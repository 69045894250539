import {ActionType} from "../constants/users";

const initialState = {
  loading: false,
  users: [],
  errors: {},
  invited: false,
  add: false,
  list: [],
  user: {}
};

const users = (state = initialState, action) => {
  switch(action.type) {
    case ActionType.GET_USERS_LIST_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload
      };
    case ActionType.GET_USER_DATA_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.INVITE_NEW_USER:
      return {
        ...state,
        invited: action.payload
      };
    case ActionType.INVITE_NEW_USER_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    case ActionType.DELETE_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.ADD_FROM_WEB:
      return {
        ...state,
        add: action.payload
      };
    case ActionType.ADD_FROM_WEB_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.GET_LIST_FOR_ADD: 
      return {
        ...state,
        list: action.payload
      };
    case ActionType.GET_LIST_FOR_ADD_ERRORS: 
      return {
        ...state,
        errors: action.payload
      };  
    case ActionType.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    case ActionType.GET_USER_INFO_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.EDIT_USER_SUCCESS: 
      return {
        ...state
      };
    case ActionType.EDIT_USER_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    default:
      return state;
  }
};

export default users;
