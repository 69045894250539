import axios from "axios";

export const BASE_URL = `https://api.honestmaps.com`
export const BASE_URL_UI = `https://honestmaps.com`

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": `application/json`,
    Accept: "application/json",
  }
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("access_token");
    if (token && token !== null) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
