import { ActionType } from "../constants/maps";

import { getMapsList, addMap, deleteMap, getMap, getMarks, setMarks, editMap } from "../../api/requests/maps";

export const getMapsListAction = (page = 1, query = '') => async (dispatch) => {
  try {
    dispatch({ type: ActionType.GET_MAPS_LIST_START })
    const res = await getMapsList(page, query)
    dispatch({ type: ActionType.GET_MAPS_LIST_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({
      type: ActionType.GET_MAPS_LIST_ERRORS,
      payload: error.response
    })
  }
};

export const getMarksAction = (id, page = 1) => async (dispatch) => {
  try {
    dispatch({ type: ActionType.GET_MARKS_LIST_START })
    const res = await getMarks(id, page)
    dispatch({ type: ActionType.GET_MARKS_LIST_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({
      type: ActionType.GET_MARKS_LIST_ERRORS,
      payload: error.response
    })
  }
};

export const getMapAction = id => async (dispatch) => {
  try {
    dispatch({ type: ActionType.GET_MAP_START })
    const res = await getMap(id)
    dispatch({ type: ActionType.GET_MAP_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({
      type: ActionType.GET_MAP_ERRORS,
      payload: error.response
    })
  }
};

export const setMarksAction = (type, mapId, markId, cb, page = 1) => async (dispatch) => {
  const data = (type) => {
    if (type === 'approve') return { status: 1 }
    if (type === 'disapprove') return { status: 2 }
  }
  try {
    const response = await setMarks(markId, data(type))
    dispatch({ type: ActionType.GET_MARKS_LIST_START })
    const res = await getMarks(mapId, page)
    dispatch({ type: ActionType.GET_MARKS_LIST_SUCCESS, payload: res.data })
    cb(type)
  } catch (error) {
    dispatch({
      type: ActionType.GET_MARKS_LIST_ERRORS,
      payload: error.response
    })
  }
};


export const addMapAction = (callback, data, page = 1, query = '') => async (dispatch) => {
  try {
    dispatch({ type: ActionType.ADD_MAP })
    const response = await addMap(data)
    dispatch({ type: ActionType.GET_MAPS_LIST_START })
    const res = await dispatch(getMapsListAction(page, query))
    callback()
    dispatch({ type: ActionType.GET_MAPS_LIST_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({
      type: ActionType.ADD_MAP_ERRORS,
      payload: error.response
    })
  }
};

export const editMapAction = (id, data, callback) => async (dispatch) => {
  try {
    await editMap(id, data)
    dispatch(getMapAction(id))
    callback()
  } catch (error) {
    dispatch({
      type: ActionType.ADD_MAP_ERRORS,
      payload: error.response
    })
  }
};

export const deleteMapAction = (id, page = 1, query = '') => async (dispatch) => {
  try {
    dispatch({ type: ActionType.DELETE_MAP })
    await deleteMap(id)
    dispatch({ type: ActionType.GET_MAPS_LIST_START })
    const res = await dispatch(getMapsListAction(page, query))
    dispatch({ type: ActionType.GET_MAPS_LIST_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({
      type: ActionType.GET_MAPS_LIST_ERRORS,
      payload: error.response
    })
  }
};

