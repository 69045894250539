import axiosInstance from "../axiosInstance";

export const getUsersList = (name, per_page, order_by, order_dir, page, client) => {
  let clientsData;
  if(client === undefined) {
    clientsData = 0;
  } else {
    clientsData = client;
  }
  return axiosInstance.get(`/api/admin/users?search=${name}&per_page=${per_page}&order_by=${order_by}&order_dir=${order_dir}&page=${page}&clients=${clientsData}`);
};

export const deleteUser = (user_id) => {
  return axiosInstance.delete(`/api/admin/users/${user_id}`);
};

export const addModerator = (data) => {
  return axiosInstance.post(`/api/admin/users`, data);
};

export const getUserInfo = (user_id) => {
  return axiosInstance.get(`/api/admin/users/${user_id}`);
};

export const editUser = (user_id, data) => {
  return axiosInstance.put(`/api/admin/users/${user_id}`, data);
};
