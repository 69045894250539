import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import MultiReactSelect from '../../../helpers/MultiReactSelect'
import { Uploader } from "../../../helpers";
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux'
import { SelectWithInfinityScroll } from "../../../components/SelectWithInfinityScroll";
import TextField from '@material-ui/core/TextField';
import { getMultipleStatesAction } from "../../../store/actions/arrays";
import { editMapAction } from "../../../store/actions/maps";
import TransitionAlerts from "../../../components/Alert";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { BASE_URL_UI } from '../../../api/axiosInstance'

const useStyles = makeStyles(theme => ({
  container: {
    padding: "16px"
  },
  back: {
    margin: "15px 0",
    display: 'flex',
    justifyContent: 'space-between'
  },
  forms: {
    margin: "25px 0"
  },
  form: {
    marginRight: "15px",
    marginTop: "15px"
  },
  image: {
    minWidth: "200px",
    maxWidth: "20%",
    margin: "15px 0"
  },
  img: {
    maxWidth: "100%"
  },
  buttons: {
    margin: "15px 0",
    marginRight: "25px"
  },
  button: {
    marginRight: "25px"
  },
  buttonSave: {
    display: 'block',
    marginLeft: "auto"
  }
}))

export const ViewMapForm = ({ map, component, to, resortId }) => {

  const classes = useStyles()
  const countries = useSelector(state => state.arrays.countries)
  const states = useSelector(state => state.arrays.states)
  const user = useSelector(state => state.auth?.me?.user?.roles[0]?.name)

  const dispatch = useDispatch()

  const [resort, setResort] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [users, setUsers] = useState([]);
  const [picture, setPicture] = useState([]);
  const [picturePreview, setPicturePreview] = useState('');
  const [formDisabled, setFormDisabled] = useState(true)
  const [alert, setAlert] = useState(false)


  const onChangeCountry = (value) => {
    setCountry(value)
    setState('')
    dispatch(getMultipleStatesAction(value.id))
  }

  const onChangeState = (value) => setState(value)
  const onChangeUser = (value) => setUsers(value)
  const onChangeResort = (e) => setResort(e.target.value)
  const onDrop = (data) => setPicture(data)
  const handleImageChange = () => setPicturePreview('')
  const handleAlert = () => {
    setAlert(true)
    setTimeout(() => setAlert(false), 3000)
  }
  const handleSaveChanges = () => {
    const form = new FormData()
    form.append('_method', 'put')
    form.append('name', resort)
    if (state && state.id) {
      form.append('state', state.id)
    }
    form.append('country', country.id)
    users && users.map(user => {
      form.append('users[]', user.id)
    })
    if (picture[0]) form.append('image', picture[0])
    dispatch(editMapAction(map.id, form, handleAlert))
  }

  useEffect(() => {
    setCountry(map.country)
    setResort(map.name)
    setState(map.state)
    setUsers(map.users)
    setPicturePreview(map.map?.map_path)
    dispatch(getMultipleStatesAction(map.country.id))
  }, [map])

  useEffect(() => {
    const checkForm = () => {
      if (resort.length > 0
        && typeof states === 'object'
        && typeof countries === 'object') {
        setFormDisabled(false)
      } else setFormDisabled(true)
    }
    checkForm()
  }, [resort, states, countries, picture,])

  useEffect(() => {

  }, [])

  return (
    <div className={classes.container}>
      <TransitionAlerts
        alertHandler={() => setAlert(false)}
        open={alert}
        info="Map updated successfully"
      />
      <div className={classes.back}>
        <Button
          component={component}
          to={to}
          color="primary"
          variant="contained"
        >
          Back
        </Button>
        <Button
          target="_blank"
          href={`${BASE_URL_UI}/${map.name.replace(/ /g, '-')}/${resortId}`}
          color="primary"
          variant="contained"
        >
          View Map
          <OpenInNewIcon />
        </Button>
      </div>
      <div className={classes.forms}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <MultiReactSelect
              single={'single'}
              value={country}
              onChange={onChangeCountry}
              options={countries}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <MultiReactSelect
              single={'single'}
              value={state}
              onChange={onChangeState}
              options={states}
              isDisabled={!states || !states.length}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              disabled={!(countries && states)}
              style={{ marginTop: "0" }}
              placeholder="Enter resort name"
              className={classes.fool}
              margin="dense"
              variant={"outlined"}
              value={resort}
              onChange={onChangeResort}
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SelectWithInfinityScroll
              onChange={onChangeUser}
              multiselect={true}
              value={users}
              disabled={user === 'admin' ? false : true}
            />
          </Grid>
          {picturePreview ?
            null
            : (<Grid item md={12} xs={12}>
              <Uploader
                onDrop={onDrop}
              />
            </Grid>)
          }
        </Grid>
      </div>
      {picturePreview ?
        (
          <>
            <div className={classes.image}>
              <img className={classes.img} src={picturePreview} ></img>
            </div>
            <div className={classes.buttons}>
              <Button className={classes.button} onClick={handleImageChange} variant="contained" color="primary" >Change Picture</Button>
            </div>
          </>
        )
        : null
      }
      <Button disabled={formDisabled} className={classes.buttonSave} onClick={handleSaveChanges} variant="contained" color="primary" >Save changes</Button>
    </div>
  )
}
