import { ActionType } from "../constants/arrays";

import {getCountries, getMultipleStates, getMultipleResorts} from "../../api/requests/arrays";

export const getCountriesAction = () => dispatch => {
  return getCountries()
    .then(dispatch({
      type: ActionType.GET_COUNTRIES_ARRAY_START
    }))
    .then(response => {
      dispatch({
        type: ActionType.GET_COUNTRIES_ARRAY_SUCCESS,
        payload: response.data
      })
    })
    .catch(error => {
      dispatch({
        type: ActionType.GET_COUNTRIES_ARRAY_ERRORS,
        payload: error.response
      })
    })
};

export const getMultipleStatesAction = (countries, statesValueaChecker) => dispatch => {
  return getMultipleStates(countries)
    .then(dispatch({
      type: ActionType.GET_MULTIPLE_STATES_START
    }))
    .then(response => {
      dispatch({
        type: ActionType.GET_MULTIPLE_STATES_SUCCESS,
        payload: response.data
      });
      return response.data;
    })
    .then((response)=> {
      statesValueaChecker(response);
    })
    .catch(error => {
      dispatch({
        type: ActionType.GET_MULTIPLE_STATES_ERRORS,
        payload: error.response
      })
    });
};

export const getMultipleResortsAction = (states, resortsValueaChecker) => dispatch => {
  return getMultipleResorts(states)
    .then(dispatch({
      type: ActionType.GET_MULTIPLE_RESORTS_START
    }))
    .then(response => {
      dispatch({
        type: ActionType.GET_MULTIPLE_RESORTS_SUCCESS,
        payload: response.data
      })
      return response.data;
    })
    .then(response => {
      resortsValueaChecker(response);
    })
    .catch(error => {
      dispatch({
        type: ActionType.GET_MULTIPLE_RESORTS_ERRORS,
        payload: error.response
      })
    });
};

export const cleareStatesAction = () => dispatch => {
  return (dispatch({
    type: ActionType.CLEARE_STATES
  }));
};

export const cleareResortsAction = () => dispatch => {
  return (dispatch({
    type: ActionType.CLEARE_RESORTS
  }))
}
