import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { getUsersList } from "../../api/requests/users";


const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    overflow: "auto",
  }),
}

const SelectWithInfinityScroll = (props) => {
  async function loadOptions(search, loadedOptions, { page }) {

    const response = await getUsersList(search, 15, "id", "desc", page, 1);
    const meta = await response.data.meta;
    const responseJSON = await response.data.data;
    const hasMore = meta.current_page < meta.last_page;
    return {
      options: responseJSON,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <AsyncPaginate
      styles={customStyles}
      value={props.value}
      isMulti={props.multiselect}
      loadOptions={loadOptions}
      onChange={props.onChange}
      isDisabled={props.disabled}
      additional={{
        page: 1,
      }}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
    />
  );
};

export default SelectWithInfinityScroll;
