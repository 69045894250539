import React, { useState } from 'react'
import { Button } from '@material-ui/core';
import { BASE_URL_UI } from '../../../api/axiosInstance'
import { useDispatch } from 'react-redux'
import { setMarksAction } from "../../../store/actions/maps";
import { useStyles } from '../styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export const ActionButtons = ({ classes, mark, resortId, map }) => {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState({ approve: false, disapprove: false })
  const handleLoading = (type) => {
    if (type === 'approve') setLoading(prev => ({ ...prev, approve: !prev.approve }))
    if (type === 'disapprove') setLoading(prev => ({ ...prev, disapprove: !prev.disapprove }))
  }
  const actionsHandler = (type, id) => {
    handleLoading(type)
    dispatch(setMarksAction(type, map?.map?.id, id, handleLoading))
  }



  return (
    <div className={classes.actions}>
      <Button
        target="_blank"
        href={`${BASE_URL_UI}/${map.name.replace(/ /g, '-')}/${resortId}/${mark.preview_id}`}
        className={`${classes.button}`}
        color="primary"
        variant="contained"
      >
        View
      </Button>

      <Button
        onClick={() => {
          actionsHandler('approve', mark?.id)
        }}
        className={`${classes.button}`}
        color="primary"
        variant="contained"
      >
        {loading.approve ? <CircularProgress color={"#fff"} size={16} /> : 'Approve'}
      </Button>

      <Button
        onClick={() => actionsHandler("disapprove", mark?.id)}
        className={`${classes.delete} ${classes.button}`}
        color="inherit"
        variant="contained"
      >
        {loading.disapprove ? <CircularProgress color={"#fff"} size={16} /> : 'Disapprove'}
      </Button>
    </div>
  )
}
