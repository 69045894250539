import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;
  
  if(!localStorage.getItem("access_token") && window.location.pathname !== "/sign-in") {
    window.location.assign("/sign-in");
  } else  if(localStorage.getItem("access_token")) {
    return (
      <React.Fragment>
        <Route
        {...rest}
        render={matchProps => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
      </React.Fragment>
    );
  }
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;

