import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import SignWrapper from 'components/SignWrapper/SignWrapper';
import {resetPasswordAction, authHandlingErrors} from "../../store/actions/auth";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#00034b',
    color: '#fff',
    '&:hover': {
      backgroundColor: ' #3c42d0'
    }
  },
  loginError: {
    background: "#fcc0ff9e",
    textAlign: "center",
    padding: "10px",
    borderRadius:"4px",
    color: "red",
    marginTop: "10px"
  }
}));

const ResetPassword = props => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    password_confirmation: "",
     password: "",
     errors: {}
  });
  
  useEffect(()=> {
    props.authHandlingErrors();
  }, []);

  const handleSignIn = event => {
    event.preventDefault();
    
    const search = new URLSearchParams(window.location.search);
    let email = search.get("email");
    let token = search.get("token");

    const data = {
      email,
      token,
      password: formState.password,
      password_confirmation: formState.password_confirmation
    };
    props.resetPasswordAction(data, redirectHandler);
  };


  const redirectHandler = () => {
    history.push("/sign-in");
  };

  const handleChange = (event) => {
    event.persist();
    if(props.errors && Object.keys(props.errors).length > 0) {
      props.authHandlingErrors();
    }

    let promise = new Promise((res, rej)=> {
      
     setFormState(formState => ({
       ...formState,
       [event.target.name]: event.target.value
     }));
     
     const newData = {
       ...formState,
       [event.target.name]: event.target.value
     }
     res(newData)
    });
     promise.then(res => {
       if(res.password.length > 0 && res.password_confirmation.length > 0) {
        setFormState(formState => ({
          ...formState,
          isValid: true,
        }));
       }
     })
  }

  return (
    <SignWrapper>
      <form className={classes.form} onSubmit={handleSignIn}>
        <Typography className={classes.title} variant="h2">
          Change Password
        </Typography>
        <TextField
          className={classes.textField}
          fullWidth
          autoComplete="off"
          label="Password"
          name="password"
          onChange={handleChange}
          type="password"
          variant="outlined"
          autoComplete={'false'}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label="Password confirmation"
          name="password_confirmation"
          onChange={handleChange}
          type="password"
          variant="outlined"
          autoComplete={'false'}
          error={props.errors && props.errors.errors && props.errors.errors.password ? true : false }
          helperText={ props.errors 
                        && props.errors.errors 
                        && props.errors.errors.password 
                        ? props.errors.errors.password :  ""}

        />
         {props.errors && props.errors.message && <div className={classes.loginError}>{props.errors.message}</div>}
        <Button
          className={classes.signInButton}
          disabled={!formState.isValid}
          fullWidth
          size="large"
          type="submit"
          variant="contained">
          Change password
        </Button>
      </form>
    </SignWrapper>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object
};

const mapDispatchToProps = {
  resetPasswordAction,
  authHandlingErrors
};

const mapStateToProps = state => {
  return {
    errors: state.auth.errors
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));
