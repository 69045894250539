import { ActionType } from "../constants/users";
import {getUsersList, deleteUser, getUserInfo, editUser} from "../../api/requests/users";
import {inviteNewUser} from "../../api/requests/invite";
import {addFromWeb} from "../../api/requests/add";

export const getUsersListAction = (
    search="", 
    per_page=15, 
    order_by="id", 
    order_dir="desc",
    page = 1
  ) => dispatch => {
  return getUsersList(search, per_page, order_by, order_dir, page)
    .then(dispatch({
      type: ActionType.GET_USERS_LIST_START,
    }))
    .then(response => {
      dispatch({
        type: ActionType.GET_USERS_LIST_SUCCESS,
        payload: response.data
      })
    })
    .catch(error => {
      dispatch({
        type: ActionType.GET_USER_DATA_ERRORS,
        payload: error.response
      })
    });
};

export const deleteUserAction = (user_id, updater) => dispatch => {
  return deleteUser(user_id)
    .then(response => {
       dispatch({
         type: ActionType.DELETE_USER
       })
    })
    .then(()=> {
      updater();
    })
    .catch(error => {
      dispatch({
        type: ActionType.DELETE_USER_ERRORS,
        payload: error.response
      })
    });
};

export const inviteNewUserAction = (data, tableDatatHandler, successCallback) => dispatch => {
  return inviteNewUser(data)
    .then(response => {
      return dispatch({
        type: ActionType.INVITE_NEW_USER,
        payload: response
      });
    })
    .then(()=> {
      tableDatatHandler();
    })
    .then(()=>successCallback())
    .catch(error => {
      dispatch({
        type: ActionType.INVITE_NEW_USER_ERRORS,
        payload: error.response.data
      });
    });
};

export const handleDeleteErrors  = (data) => dispatch =>{
  return dispatch({
    type: ActionType.DELETE_ERRORS,
    payload:data
  });
};

export const addFromWebAction = (userId, data, cleareDataHandler, successCallback) => dispatch => {
  return addFromWeb(userId, data)
    .then(response => {
      dispatch({
        type: ActionType.ADD_FROM_WEB,
        payload: response.data
      })
    })
    .then(()=> {
      cleareDataHandler();
    })
    .then(()=> successCallback())
    .catch(error => {
      dispatch({
        type: ActionType.ADD_FROM_WEB_ERRORS,
        payload: error.response
      })
    });
};

export const getListForAddAction = (
  search="", 
  per_page=15, 
  order_by="id", 
  order_dir="desc",
  page = 1
) => dispatch => {
return getUsersList(search, per_page, order_by, order_dir, page)
  .then(response => {
    dispatch({
      type: ActionType.GET_LIST_FOR_ADD,
      payload: response.data
    })
  })
  .catch(error => {
    dispatch({
      type: ActionType.GET_LIST_FOR_ADD_ERRORS,
      payload: error.response
    })
  });
};

export const getUserInfoAction = (user_id, callback) => dispatch => {
  return getUserInfo(user_id)
    .then(response => {
      dispatch({
        type: ActionType.GET_USER_INFO_SUCCESS,
        payload: response.data
      })
    })
    .then(() => {
      callback();
    })
    .catch(error => {
      dispatch({
        type: ActionType.GET_USER_INFO_ERRORS,
        payload: error.response
      })
    });
};

export const editUserAction = (user_id, data, callback) => dispatch =>{
  return editUser(user_id, data)
    .then(() => {
      callback();
      dispatch({
        type: ActionType.EDIT_USER_SUCCESS
      })
    })
    .catch(errors => {
      dispatch({
        type: ActionType.EDIT_USER_ERRORS,
        payload: errors.response.data
      })
    })
};
