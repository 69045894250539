import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField, Link, Typography } from '@material-ui/core';
import SignWrapper from 'components/SignWrapper/SignWrapper';
import {connect} from "react-redux";
import {fogotPasswordAction, 
        authHandlingErrors
        } from "../../store/actions/auth";
import {isSubmitResetOpen} from "../../store/actions/modals";
import SubmitReset from "../../components/Modals/ModalsComponents/SubmitReset";

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
};

const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },

  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#00034b',
    color: '#fff',
    '&:hover': {
      backgroundColor: ' #3c42d0'
    }
  },
  loginError: {
    background: "#fcc0ff9e",
    textAlign: "center",
    padding: "10px",
    borderRadius: "4px",
    color: "red",
    marginTop: "10px"
  }
}));

const ForgotPassword = props => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });


  useEffect(()=> {
    if (props.errors && Object.keys(props.errors).length !== 0) {
      props.authHandlingErrors();
       // eslint-disable-next-line react/jsx-sort-props
    }
    // eslint-disable-next-line react/jsx-sort-props
  }, [])

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    if (props.errors && Object.keys(props.errors).length !== 0) {
      props.authHandlingErrors();
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {
      email: formState.values.email,
      to_admin: 1
    };
    props.fogotPasswordAction(data, redirectHandler);
  };

  const redirectHandler = () => {
    props.isSubmitResetOpen();
  };
  
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <SignWrapper>
        <SubmitReset 
          open={props.open}
        />
      <form className={classes.form} onSubmit={handleSubmit}>
        <Typography className={classes.title} variant="h2">
          Forgot password
        </Typography>
        <TextField
          className={classes.textField}
          error={hasError('email')}
          fullWidth
          helperText={
            hasError('email') 
              ? formState.errors.email[0] 
              : props.errors && props.errors.email 
                ? props.errors.email[0]
                : null
          }
          label="Email address"
          name="email"
          onChange={handleChange}
          type="text"
          value={formState.values.email || ''}
          variant="outlined"
        />
        {props.errors && props.errors.message && <div className={classes.loginError}>{props.errors.message}</div>}
        <Button
          className={classes.signInButton}
          disabled={((formState.errors 
                      && formState.errors.email 
                      && formState.errors.email[0]) 
                      || (formState.values && formState.values.email && formState.values.email === "") 
                      || (props.errors && Object.keys(props.errors).length !== 0)) 
                        ? true 
                        :false}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          >
          Reset password 
        </Button>
        <Typography color="textSecondary" variant="body1">
          Remember password?{' '}
          <Link component={RouterLink} to="/sign-in" variant="h6">
            Sign in
          </Link>
        </Typography>
      </form>
  </SignWrapper>
  
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.object
};

const mapDispatchToProps = {
  fogotPasswordAction,
  authHandlingErrors,
  isSubmitResetOpen
}

const mapStateToProps = state => {
  return {
    errors: state.auth.errors,
    open: state.modals.isSubmitReset
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));
