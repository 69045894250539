import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ChooseResorts from "../../../../ChooseResorts";
import { connect } from "react-redux";
import { isAddModeratorClose } from "../../../../../store/actions/modals";
import { getUsersListAction, addFromWebAction } from "../../../../../store/actions/users";
import { inviteNewUserAction, handleDeleteErrors } from "../../../../../store/actions/users";
import {
  getMultipleStatesAction,
  cleareStatesAction,
  getMultipleResortsAction,
  cleareResortsAction,
} from "../../../../../store/actions/arrays";
import { SelectWithInfinityScroll } from "../../../../../components/SelectWithInfinityScroll";
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: "10px"
  },
  fool: {
    width: "100%",
    margin: 0
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#00034b',
    color: '#fff',
    '&:hover': {
      backgroundColor: ' #3c42d0'
    }
  }
}));

const Add = (props) => {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [resorts, setResorts] = useState([]);
  const [user, setUser] = useState("");

  const onChangeCountry = (value) => {
    if (value) {
      let countriesIds = [];
      value.map(countryItem => countriesIds.push(countryItem.id));
      setCountries(value);
      changeStatesArrayAction(value, countriesIds);
    }
  };

  const changeStatesArrayAction = (value, countriesIds) => {
    if (value.length > 0) {
      props.getMultipleStatesAction(countriesIds, statesValueaChecker);
    } else {
      if (states.length > 0) {
        setStates([]);
        props.cleareStatesAction();
      }
      if (resorts.length > 0) {
        setResorts([]);
        props.cleareResortsAction();
      }
    }
  };

  const statesValueaChecker = (data) => {
    const newStatesArray = [];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < states.length; j++) {
        if (states[j].id === data[i].id) {
          newStatesArray.push(states[j]);
        }
      }
    }
    setStates(newStatesArray);
  };

  const onChangeResort = (value) => {
    if (value) {
      setResorts(value);
    }
  };

  const changeResortsArrayAction = (value, resortsIds) => {
    if (value.length > 0) {
      props.getMultipleResortsAction(resortsIds, resortsValueaChecker);
    } else {
      if (resorts.length > 0) {
        setResorts([]);
        props.cleareResortsAction();
      }
    }
  };

  const resortsValueaChecker = (data) => {
    const newResortsArray = [];

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < resorts.length; j++) {
        if (resorts[j].id === data[i].id) {
          newResortsArray.push(resorts[j]);
        }
      }
    }
    setResorts(newResortsArray);
  };

  const onChangeState = (value) => {
    if (value) {
      let statesIds = [];
      value.map(stateItem => statesIds.push(stateItem.id));
      setStates(value);
      changeResortsArrayAction(value, statesIds);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const resortsData = [];
    resorts.map(item => resortsData.push(item.id));
    const countriesData = [];
    countries.map(item => countriesData.push(item.id));
    const statesData = [];
    states.map(item => statesData.push(item.id));

    const data = {
      resorts: resortsData,
      countries: countriesData,
      states: statesData
    }

    props.addFromWebAction(user.id, data, cleareDataHandler, props.handleAlert);
  };

  const cleareDataHandler = () => {
    setCountries([]);
    setStates([]);
    setResorts([]);
    setUser([]);
    props.handleDeleteErrors({});
    props.isAddModeratorClose();
    props.getUsersListAction();
  };

  const onChangeUser = (value) => {
    setUser(value);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid
        container
        spacing={2}
        className={`${classes.fool}`}
      >
        <Grid item xs={12}>
          <h4 className={classes.header}>
            Registered user
          </h4>
          <SelectWithInfinityScroll
            onChange={onChangeUser}
            value={user}
          />

        </Grid>
      </Grid>
      <ChooseResorts
        onChangeCountry={onChangeCountry}
        onChangeState={onChangeState}
        resortsData={resorts}
        statesData={states}
        countriesData={countries}
        onChangeResort={onChangeResort}
      />
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <Button
            disabled={!user.id
              || resorts.length <= 0
            }
            className={classes.signInButton}
            fullWidth
            size="large"
            type="submit"
            variant="contained">
            Invite
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = {
  getMultipleStatesAction,
  cleareStatesAction,
  getMultipleResortsAction,
  cleareResortsAction,
  inviteNewUserAction,
  handleDeleteErrors,
  getUsersListAction,
  isAddModeratorClose,
  addFromWebAction
};

export default connect(null, mapDispatchToProps)(Add);

