import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ImageIcon from '@material-ui/icons/Image';
import { SidebarNav } from './components';
import {useSelector, useDispatch} from 'react-redux'
import {logoutAction} from '../../../../store/actions/auth'
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const permission = useSelector(store => store?.auth?.me?.user?.roles[0]?.name)
  const [logout, setLogout] = useState(false)

  const signOutHandler = (event) => {
    event.preventDefault();
    dispatch(logoutAction());
    localStorage.removeItem("access_token");
    setLogout(true)
  };

  const pages = [
    {
      title: 'Dashboard',
      href: '/',
      permission: ["admin", 'moderator'],
      icon: <DashboardIcon />
    },
    {
      title: 'Manage Moderators',
      href: '/manage-moderators',
      permission: ["admin"],
      icon: <PeopleIcon />
    },
    {
      title: "Manage Maps",
      href: '/manage-maps',
      permission: ["admin", 'moderator'],
      icon: <ImageIcon />
    }
  ];

  return (
    <>
    {logout ? <Redirect to = '/sign-in' /> : null}
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <SidebarNav
          className={classes.nav}
          pages={pages}
          permission = {permission}
          signOutHandler = {signOutHandler}
        />
      </div>
    </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
