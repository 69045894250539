import React, {useEffect} from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import {connect} from "react-redux";
import {logoutAction} from "../../../../store/actions/auth";
import {getCountriesAction} from "../../../../store/actions/arrays";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  header: {
    color: "#ffff"
  }
}));

const Topbar = props => {
  const { getCountriesAction, staticContext, className, onSidebarOpen,logoutAction, history, ...rest } = props;

  const classes = useStyles();

  const signOutHandler = (event) => {
    event.preventDefault();
    logoutAction();
    localStorage.removeItem("access_token");
    history.push('/sign-in')
  };

  useEffect(()=> {
    getCountriesAction();
  }, [])

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <h2 className={classes.header}>Honestmaps Admin</h2>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton className={classes.signOutButton} color="inherit" onClick={signOutHandler}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  getCountriesAction: PropTypes.func
};

const mapDispatchToProps = {
  logoutAction,
  getCountriesAction
};

export default connect(null, mapDispatchToProps)(withRouter(Topbar));
