import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/styles';
import ViewsToolbar from "../../components/viewsToolbar";
import TableData from "../../components/Table";
import ManageMapTable from "./components";
import { getMapsListAction, deleteMapAction } from "../../store/actions/maps";
import AddNewMap from "../../components/Modals/ModalsComponents/AddNewMap";
import { isAddMapOpen } from "../../store/actions/modals";
import { useDispatch, useSelector } from 'react-redux';
import TransitionAlerts from "../../components/Alert";

const headCells = [
  { id: 'country', numeric: false, disablePadding: true, label: 'Country' },
  { id: 'region', numeric: true, disablePadding: false, label: 'Region' },
  { id: 'resort', numeric: true, disablePadding: false, label: 'Resort' },
  { id: 'moderators', numeric: true, disablePadding: false, label: 'Moderators' },
  { id: 'Drawings', numeric: true, disablePadding: false, label: 'Not approved drawings' },
];

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  actions: {
    display: "flex",
  },
  delete: {
    backgroundColor: "#e57373",
    "&:hover": {
      backgroundColor: "#f44336",
    }
  },
  button: {
    fontSize: "11px",
    color: "#ffff",
    margin: "5px"
  }
}));


const ManageMaps = (props) => {

  const maps = useSelector(state => state.maps.maps)
  const loading = useSelector(state => state.maps.loading)
  const errors = useSelector(state => state.maps?.errors?.data?.errors)
  const classes = useStyles();
  const topRef = useRef();
  const [page, setPage] = useState(() => maps?.meta?.current_page ? maps?.meta?.current_page : 1);
  const [search, setSearch] = useState('');
  const [deleteMapAlert, setDeleteMapAlert] = useState(false)
  const [addMapAlert, setAddMapAlert] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getMapsListAction(page, search));
  }, [page, search]);


  const actionsHandler = (type, id) => {
    if (type === 'delete' && !loading) {
      dispatch(deleteMapAction(id, page, search))
      alertHandler('delete')
    }
  }

  const alertHandler = (type) => {
    let promise = new Promise((res, rej) => {
      if (type === 'delete') res(setDeleteMapAlert(true))
      if (type === 'add') res(setAddMapAlert(true))
    });
    promise.then(() => {
      setTimeout(() => {
        alertHandlerClose();
      }, 2000);
    })
  };

  const alertHandlerClose = () => {
    setDeleteMapAlert(false)
    setAddMapAlert(false)
  }

  const handleSearch = (query) => {
    setSearch(query)
    setPage(1)
  }

  const handleChangePage = (page) => {
    setPage(page)
  }

  const addNew = () => {
    dispatch(isAddMapOpen());
  };

  return (
    <div className={classes.root} ref={topRef}>
      {maps
        ? (
          <>
            <TransitionAlerts
              alertHandler={alertHandlerClose}
              open={deleteMapAlert}
              info="Map deleted successfully"
            />
            <TransitionAlerts
              alertHandler={alertHandlerClose}
              open={addMapAlert}
              info="Map added successfully"
            />
            <AddNewMap open={true} alertHandler={alertHandler} loading={loading} errors={errors} />
            <ViewsToolbar
              onClick={addNew}
              handleSearch={handleSearch}
              title={"Add new"}
              placeholder={"Search map"}
            />
            <div className={classes.content}>
              <TableData
                rowCount={maps.data.length}
                rowsPerPage={maps.meta.per_page}
                page={maps.meta.current_page}
                last_page={maps.meta.last_page}
                totalItemsCount={maps.meta.total}
                itemsCountPerPage={maps.meta.per_page}
                topRef={topRef}
                users={maps.data}
                onChange={handleChangePage}
                handleRequestSort={() => { }}
                headCells={headCells}
              >
                <ManageMapTable
                  data={maps.data}
                  actionsHandler={actionsHandler}
                  styles={classes}
                  loading={loading}
                />
              </TableData>
            </div>
          </>)
        : (<div className="loader"></div>)
      }

    </div>);
};

export default ManageMaps;
