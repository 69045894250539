import React from 'react';
import ImageUploader from 'react-images-upload';

const Uploader = (props) => {
  return (
    <ImageUploader
      singleImage={true}
      withIcon={true}
      buttonText='Choose image'
      onChange={props.onDrop}
      imgExtension={['.jpg', '.gif', '.png', '.gif']}
      maxFileSize={10242880}
      withPreview={true}
      label='Max file size: 10mb, accepted: jpg|gif|png'
    />
  );
};

export default Uploader;
