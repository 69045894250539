import React, { useEffect, useState } from 'react';
import { getPendingMarks } from '../../api/requests/maps';
import { BASE_URL_UI } from '../../api/axiosInstance';
import { setMarksAction } from '../../store/actions/maps';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    marginRight: '10px',
    height: '30px',
    fontSize: '11px'
  },
  delete: {
    color: '#fff',
    backgroundColor: '#e57373',
    '&:hover': {
      backgroundColor: '#f44336'
    }
  }
}));

const Dashboard = () => {
  const [data, setData] = useState();
  const [approve, setApprove] = useState(false);
  const [disapprove, setDisapprove] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    getPendingMarks().then((res) => setData(res.data.data));
  }, []);

  const actionsHandler = (type, map_id, id) => {
    if (type === 'approve') {
      setApprove(true);
    } else {
      setDisapprove(true);
    }
    dispatch(setMarksAction(type, map_id, id));
    setTimeout(
      () =>
        getPendingMarks().then((res) => {
          setData(res.data.data);
          setApprove(false);
          setDisapprove(false);
        }),
      2000
    );
  };

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Resort</TableCell>
              <TableCell>Moderator</TableCell>
              <TableCell>Creation Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.id}</TableCell>
                <TableCell component="th" scope="row">
                  {row.region}
                </TableCell>
                <TableCell>{row.ski_area}</TableCell>
                <TableCell>{row.user_name}</TableCell>
                <TableCell>
                  {moment(row.created).format('DD-MM-YYYY HH:mm')}
                </TableCell>
                <TableCell>
                  <div className={classes.buttons}>
                    <Button
                      target="_blank"
                      href={`${BASE_URL_UI}/${row.ski_area.replace(
                        / /g,
                        '-'
                      )}/${row.ski_area_id}/${row.preview_id}`}
                      className={`${classes.button}`}
                      color="primary"
                      variant="contained">
                      View
                    </Button>
                    <Button
                      onClick={() => {
                        actionsHandler('approve', row.map_id, row.id);
                      }}
                      className={`${classes.button}`}
                      color="primary"
                      variant="contained">
                      {approve ? (
                        <CircularProgress color={'#fff'} size={16} />
                      ) : (
                        'Approve'
                      )}
                    </Button>
                    <Button
                      onClick={() =>
                        actionsHandler('disapprove', row.map_id, row.id)
                      }
                      className={`${classes.delete} ${classes.button}`}
                      color="inherit"
                      variant="contained">
                      {disapprove ? (
                        <CircularProgress color={'#fff'} size={16} />
                      ) : (
                        'Disapprove'
                      )}
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Dashboard;
