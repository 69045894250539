import { ActionType } from "../constants/maps";
import { map } from "underscore";

const initialState = {
  loading: true,
  maps: null,
  map: {
    loading: true,
    data: null,
    marks: null,
  },
};

const maps = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_MAPS_LIST_START:
    case ActionType.GET_MAP_START:
      return {
        ...state,
        loading: true
      }
    case ActionType.GET_MARKS_LIST_START:
      return {
        ...state,
        map: {
          ...state.map,
          loading: true
        }
      };
    case ActionType.GET_MAPS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        maps: action.payload
      };
    case ActionType.GET_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        map: { ...state.map, data: action.payload }
      };
    case ActionType.GET_MARKS_LIST_SUCCESS:
      return {
        ...state,
        map: { ...state.map, loading: false, marks: action.payload }
      };
    case ActionType.ADD_MAP:
      return {
        ...state,
        loading: true
      };
    case ActionType.DELETE_MAP:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_MAPS_LIST_ERRORS:
    case ActionType.ADD_MAP_ERRORS:
    case ActionType.GET_MARKS_LIST_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    default:
      return state;
  }
};

export default maps;
