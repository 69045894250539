import React, { useState, useEffect, useRef } from "react";
import { useStyles } from './styles'
import TransitionAlerts from "../../components/Alert";
import { Link } from "react-router-dom";
import ViewsToolbar from "../../components/viewsToolbar";
import Table from './components/Table'
import { getMapAction, getMarksAction, setMarksAction } from "../../store/actions/maps";
import { useDispatch, useSelector } from 'react-redux'
import { ViewMapForm } from './components/ViewMapForm'
import TableData from "../../components/Table";
import { isAddMapOpen } from "../../store/actions/modals";
import AddNewMap from "../../components/Modals/ModalsComponents/AddNewMap";
import { Paper } from "@material-ui/core";

const headCells = [
  { id: 'submission_id', numeric: false, disablePadding: true, label: 'Drawing ID' },
  { id: 'username', numeric: false, disablePadding: true, label: 'User Name' },
  { id: 'date', numeric: true, disablePadding: false, label: 'Creation Date' },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
];


const ViewMap = (props) => {

  const classes = useStyles()
  const topRef = useRef()
  const dispatch = useDispatch()
  const map = useSelector(store => store.maps.map.data)
  const marks = useSelector(store => store.maps.map.marks)
  const loading = useSelector(store => store.maps.loading)
  const marksLoading = useSelector(store => store.maps.map.loading)
  const errors = useSelector(state => state.maps?.errors?.data?.errors)
  const resortId = props.match.params.id
  const [page, setPage] = useState(() => marks?.meta?.current_page ? marks?.meta?.current_page : 1);

  useEffect(() => {
    dispatch(getMapAction(resortId))
  }, [resortId])

  useEffect(() => {
    if (!map?.map?.id) return
    dispatch(getMarksAction(map?.map?.id, page))
  }, [map, page])

  const handleChangePage = (number) => setPage(number)


  const handleModalOpen = () => dispatch(isAddMapOpen())

  return map && marks && !loading
    ? (
      < div className={classes.root} ref={topRef} >
        <>
          <Paper>
            <AddNewMap open={true} loading={loading} edit={true} errors={errors} map={map} />
            <ViewMapForm
              map={map}
              handleModalOpen={handleModalOpen}
              edit={true}
              component={Link}
              to={'/manage-maps'}
              resortId = {resortId}
            />
          </Paper>
          <div className={classes.content}>
            <TableData
              rowCount={marks.data.length}
              rowsPerPage={marks.meta.per_page}
              page={marks.meta.current_page}
              last_page={marks.meta.last_page}
              totalItemsCount={marks.meta.total}
              itemsCountPerPage={marks.meta.per_page}
              topRef={topRef}
              users={marks.data}
              onChange={handleChangePage}
              handleRequestSort={() => { }}
              headCells={headCells}
            >
              <Table
                data={marks.data}
                map={map}
                styles={classes}
                loading={loading}
                resortId={resortId}
              />
            </TableData>
          </div>
        </>
      </div >
    )
    : (<div className="loader"></div>)

}
export default ViewMap;
