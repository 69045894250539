import React from "react";
import {connect} from "react-redux";
import ModalsWrapper from "../../ModalsWrapper";
import { makeStyles } from '@material-ui/core/styles';
import {isDeleteModeratorClose} from "../../../../store/actions/modals";
import { editUserAction, getUsersListAction, deleteUserAction} from "../../../../store/actions/users";
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px",
    maxHeight: "90%",
    overflow: "auto",
    width: "100%",
    maxWidth: "540px",
    outline: "none",
    overflow: "visible"
  },
  acvtiveBtn: {
    backgroundColor: "#1a237e"
  },
  title: {
    margin: "2em 0",
    textAlign: "center"
  },
  fool: {
    width: "100%"
  },
  margin: {
    margin: 0,
  },
  padding: {
    padding: "8px"
  },
  header: {
    marginBottom: "10px"
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#00034b',
    color: '#fff',
    '&:hover': {
      backgroundColor: ' #3c42d0'
    }
  },
  buttons: {
    display: "flex", 
    justifyContent: "space-between" 
  },
  deleteButton: {
    color: "#fff",
    backgroundColor: "#e57373",
    '&:hover': {
      backgroundColor: "#f44336"
    }
  }
}));

const DeleteModerator = (props) => {
  const classes = useStyles();

  const handleClose = () => {
    props.isDeleteModeratorClose();
  };

  const handleDelete = () => {
    props.deleteUserAction(props.user.id, props.deleteHandler);
    props.isDeleteModeratorClose();
    props.alertHandler('delete');
  }
  
  return ( 
    <ModalsWrapper open={props.open}>
      <div className = {classes.paper}>
        <h2 
          id="simple-modal-title" 
          className={classes.title}
        >Are you sure you want to delete {props.user.name ? props.user.name : "this moderator"}? 
        </h2>
        <div className = {classes.buttons}>
        <Button onClick = {handleClose} variant="outlined">Cancel</Button>
        <Button onClick = {handleDelete} variant="contained" className = {classes.deleteButton}>Delete</Button>
        </div>
      </div>
    </ModalsWrapper>
  );
};

const mapStateToProps = state => {
  return {
    open: state.modals.isDeleteModerator,
    user: state.users.user,
  };
};

const mapDispatchToProps = {
  isDeleteModeratorClose,
  getUsersListAction,
  editUserAction,
  deleteUserAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModerator);
