import React from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import MultiReactSelect from "../../helpers/MultiReactSelect";
import Grid from '@material-ui/core/Grid';
import {
  getMultipleStatesAction,
  cleareStatesAction,
  getMultipleResortsAction,
  cleareResortsAction,
} from "../../store/actions/arrays";

const useStyles = makeStyles((theme) => ({
  fool: {
    width: "100%"
  },
  bottom: {
    marginBottom: "20px"
  },
  header: {
    marginBottom: "10px"
  },
  margin: {
    margin: 0,
  },
  padding: {
    padding: "8px"
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#00034b',
    color: '#fff',
    '&:hover': {
      backgroundColor: ' #3c42d0'
    }
  }
}));


const ChooseResorts = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        className={`${classes.margin} ${classes.fool}`}
      >
        <Grid item md={6} xs={12}>
          <h4 className={classes.header}>
            Countries
          </h4>
          <MultiReactSelect
            single={props.single}
            value={props.countriesData}
            onChange={props.onChangeCountry}
            options={props.countries}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <h4 className={classes.header}>
            States
          </h4>
          <MultiReactSelect
            single={props.single}
            value={props.statesData}
            onChange={props.onChangeState}
            options={props.states}
            isDisabled={
              (props.countriesData && props.countriesData.length <= 0)
                || (!props.states || props.states.length <= 0)
                ? true
                : false}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        className={`${classes.fool}  ${classes.margin}`}
      >
        {props.hideResort ? null :
          (<Grid item md={12} xs={12}>
            <h4 className={classes.header}>
              Resorts
          </h4>
            <MultiReactSelect
              single={props.single}
              isError={
                props.errors
                  && props.errors.errors
                  && props.errors.errors.resorts
                  ? true
                  : false}
              value={props.resortsData}
              onChange={props.onChangeResort}
              options={props.resorts}
              isDisabled={
                (props.countriesData && props.countriesData.length <= 0)
                  || (props.statesData && props.statesData.length <= 0)
                  || (!props.resorts || props.resorts.length <= 0)
                  ? true
                  : false}
            />
            {props.errors
              && props.errors.errors
              && props.errors.errors.resorts
              && (<div className="error-text">{props.errors.errors.resorts}</div>)
            }
          </Grid>)
        }
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    countries: state.arrays.countries,
    loading: state.arrays.loading,
    states: state.arrays.states,
    resorts: state.arrays.resorts,
    errors: state.users.errors
  };
};

const mapDispatchToProps = {
  getMultipleStatesAction,
  cleareStatesAction,
  getMultipleResortsAction,
  cleareResortsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseResorts);
