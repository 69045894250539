import React from 'react'
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const customStyles = {
  menu: (provided, state) => ({
    ...provided, 
  }),
}

const MultiReactSelect = (props) => {

  return (
    <ReactSelect
      onChange={props.onChange}
      styles={customStyles}
      closeMenuOnSelect={true}
      value={props.value}
      components={animatedComponents}
      isMulti={props.single === "single" ? false : true}
      options={props.options}
      className={`textField ${props.isError ? "red-borders" : ""}`}
      getOptionLabel ={(option)=>option.name}
      getOptionValue ={(option)=>option.id}
      isDisabled={props.isDisabled}
    />
  );
};

export default MultiReactSelect;
