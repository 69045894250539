import {combineReducers} from "redux";
import {
          modals, 
          auth, 
          users,
          maps,
          arrays
       } from "./reducers";

const RootReducer = combineReducers({
  modals,
  auth,
  users,
  maps,
  arrays
});

export default RootReducer;
