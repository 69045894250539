export const ActionType  = {
  GET_USERS_LIST_START: "GET_USERS_LIST_START",
  GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
  GET_USERS_LIST_ERRORS: "GET_USERS_LIST_ERRORS",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_ERRORS: "DELETE_USER_ERRORS",
  GET_USER_DATA_START: "GET_USER_DATA_START",
  GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
  GET_USER_DATA_ERRORS: "GET_USER_DATA_ERRORS",
  INVITE_NEW_USER: "INVITE_NEW_USER",
  INVITE_NEW_USER_ERRORS: "INVITE_NEW_USER_ERRORS",
  DELETE_ERRORS: "DELETE_ERRORS",
  ADD_FROM_WEB: "ADD_FROM_WEB",
  ADD_FROM_WEB_ERRORS: "ADD_FROM_WEB_ERRORS",
  GET_LIST_FOR_ADD: "GET_LIST_FOR_ADD",
  GET_LIST_FOR_ADD_ERRORS: "GET_LIST_FOR_ADD_ERRORS",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_ERRORS: "GET_USER_INFO_ERRORS",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_ERRORS: "EDIT_USER_ERRORS"
};
