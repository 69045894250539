import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto"
  },
}));

const ModalsWrapper = (props) => {
  const classes = useStyles();
  const [container] = useState(document.createElement("div"));
   container.classList.add("portal");
  
   useEffect(()=> {
      document.body.appendChild(container);
      return ()=> {
        document.body.removeChild(container);
      };
   }, [])


  return ReactDOM.createPortal(      
    <Modal
      className={classes.paper}
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {props.children}
    </Modal>,
    container
    );
};

export default ModalsWrapper;
