export const ActionType = {
  GET_MAPS_LIST_START: "GET_MAPS_LIST_START",
  GET_MAPS_LIST_SUCCESS: "GET_MAPS_LIST_SUCCESS",
  GET_MAP_START: "GET_MAP_START",
  GET_MARKS_LIST_START: "GET_MARKS_LIST_START",
  GET_MARKS_LIST_SUCCESS: "GET_MARKS_LIST_SUCCESS",
  GET_MARKS_LIST_ERRORS: "GET_MARKS_LIST_ERRORS",
  GET_MAP_SUCCESS: "GET_MAP_SUCCESS",
  GET_MAP_ERRORS: "GET_MAP_ERRORS",
  ADD_MAP: "ADD_MAP",
  DELETE_MAP: "DELETE_MAP",
  GET_MAPS_LIST_ERRORS: "GET_MAPS_LIST_ERRORS",
  ADD_MAP_ERRORS: "ADD_MAP_ERRORS",
};
