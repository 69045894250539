import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import ModalsWrapper from "../../ModalsWrapper";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {isEditModeratorClose} from "../../../../store/actions/modals";
import ChooseResorts  from "../../../ChooseResorts";
import {handleDeleteErrors, editUserAction, getUsersListAction} from "../../../../store/actions/users";
import { Button } from '@material-ui/core';
import {
  getMultipleStatesAction,
  cleareStatesAction,
  getMultipleResortsAction,
  cleareResortsAction,
  } from "../../../../store/actions/arrays";
import validate from 'validate.js';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px",
    maxHeight: "90%",
    overflow: "auto",
    width: "100%",
    maxWidth: "540px",
    outline: "none",
    overflow: "visible"
  },
  acvtiveBtn: {
    backgroundColor: "#1a237e"
  },
  bottom: {
    marginBottom: "20px",
  },
  cross: {
    color: "#6f6f6f",
    textAlign: "right",
    fontWeight: "bold",
    fontSize: "24px",
    cursor: "pointer",
    position: "absolute",
    top: "2px",
    right: "14px"
  },
  fool: {
    width: "100%"
  },
  margin: {
    margin: 0,
  },
  padding: {
    padding: "8px"
  },
  header: {
    marginBottom: "10px"
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#00034b',
    color: '#fff',
    '&:hover': {
      backgroundColor: ' #3c42d0'
    }
  }
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const EditModerator = (props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countriesData, setCountriesData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [resortsData, setResortsData] = useState([]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleClose = () => {
    props.isEditModeratorClose();
    setTimeout(()=> {
      dataCleaner();
    })
  };

  const dataCleaner = () => {
    setName("");
    setEmail("");
    setCountriesData([]);
    setStatesData([]);
    setResortsData([]);
  };

  useEffect(()=> {
    setName(props.user.name);
    setFormState(formState => ({
      ...formState,
      values: {email: props.user.email},
    }));

    setCountriesData(props.user.counties);
    setResortsData(props.user.ski_areas);
    setStatesData(props.user.states);
    if(  props.user.states
        && props.user.counties) {
          const statesId = props.user.counties.map(item => {
            return item.id;
          });
          const resortsId = props.user.states.map(item => {
            return item.id;
          });
          getData(statesId, resortsId);
        }
  }, [props.user]);
  
  const getData = (statesId, resortsId) => {
    props.getMultipleStatesAction(statesId);
    props.getMultipleResortsAction(resortsId);
  };

  const onChangeCountry = (value) => {
    if(value) {
      let countriesIds = [];
      value.map(countryItem => countriesIds.push(countryItem.id));
      setCountriesData(value);
      changeStatesArrayAction(value, countriesIds);
    }
  };

const changeStatesArrayAction = (value, countriesIds) => {
  if(value.length > 0) {
    props.getMultipleStatesAction(countriesIds, statesValueaChecker);
  } else {
    if(statesData.length > 0) {
      setStatesData([]);
      props.cleareStatesAction();
    }
    if(resortsData.length > 0) {
      setResortsData([]);
      props.cleareResortsAction();
    }
  }
};

const statesValueaChecker = (data) => {
  const newStatesArray = [];
  for(let i = 0; i < data.length; i++) {
    for(let j = 0; j < statesData.length; j++) {
       if(statesData[j].id === data[i].id) {
         newStatesArray.push(statesData[j]);
       }
    }
   }
   setStatesData(newStatesArray);
};
  
  const onChangeResort = (value) => {
    if (props.errors 
      && props.errors.errors 
      && props.errors.errors.resorts 
      && Object.keys(props.errors.errors.resorts ).length !== 0) {
    let copy = JSON.parse(JSON.stringify(props.errors));
      delete copy.errors.resorts
      props.handleDeleteErrors(copy);
    }
    if(value) {
      setResortsData(value);
    }
  };

  const changeResortsArrayAction = (value, resortsIds) => {
    if(value.length > 0) {
      props.getMultipleResortsAction(resortsIds, resortsValueaChecker);
    } else {
      if(resortsData.length > 0) {
        setResortsData([]);
        props.cleareResortsAction();
      }
    }
  };

  const resortsValueaChecker = (data) => {
    const newResortsArray = [];
  
    for(let i = 0; i < data.length; i++) {
      for(let j = 0; j < resortsData.length; j++) {
         if(resortsData[j].id === data[i].id) {
          newResortsArray.push(resortsData[j]);
         }
      }
     }
     setResortsData(newResortsArray);
  };

  const onChangeState = (value) => {
    if(value) {
      let statesIds = [];
      value.map(stateItem => statesIds.push(stateItem.id));
      setStatesData(value);
      changeResortsArrayAction(value, statesIds);
    }
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const resorts = [];
    resortsData.map(item => resorts.push(item.id));
    const countries = [];
    countriesData.map(item => countries.push(item.id));
    const states = [];
    statesData.map(item => states.push(item.id));

    const data = {
      resorts: resorts,
      countries: countries,
      states: states,
      name: name,
      email: formState.values.email,
    }
    props.editUserAction(props.user.id,data, changeDataHandler);
  };

  const changeDataHandler = () => {
    handleClose();
    props.alertHandler('edit')
    props.getUsersListAction(
      props.search, 
      15, 
      props.order_by, 
      props.order_dir,
      props.page
    );
  }

  const handleChange = event => {
    event.persist();
    if (props.errors 
        && props.errors.errors 
        && props.errors.errors.email 
        && Object.keys(props.errors.errors.email ).length !== 0) {
      let copy = JSON.parse(JSON.stringify(props.errors));
      delete copy.errors.email
      props.handleDeleteErrors(copy);
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
  
  const handleChangeName = (event) => {
    if (props.errors 
      && props.errors.errors 
      && props.errors.errors.name 
      && Object.keys(props.errors.errors.name ).length !== 0) {
    let copy = JSON.parse(JSON.stringify(props.errors));
    delete copy.errors.name
    props.handleDeleteErrors(copy);
  }
    setName(event.target.value);
  };

  const hasError = field =>
  formState.touched[field] && formState.errors[field] ? true : false;

  return ( 
    <ModalsWrapper open={props.open}>
    <form className={classes.paper} onSubmit={handleSubmit}>
      <div>
       <div className={classes.cross} onClick={handleClose}>x</div>
        <h2 
          id="simple-modal-title" 
          className={classes.bottom}
        >Edit Moderator
        </h2>
        {props.user.name 
          && props.user.email
          && props.user.counties
          && props.user.ski_areas
          && props.user.states
          && resortsData
          && statesData
          && countriesData
              ?
              (<React.Fragment>
                <Grid 
                container 
                className={`${classes.bottom} ${classes.padding}`}
                >
                  <Grid item md={12} xs={12}>
                    <h4 className={classes.header}>
                        Name
                    </h4>
                    <TextField
                      error={ 
                        props.errors 
                        && props.errors.errors
                        && props.errors.errors.name 
                          ? true
                          : false
                      }
                      helperText={ props.errors 
                        && props.errors.errors
                        && props.errors.errors.name 
                          ? props.errors.errors.name
                          : ""}
                      name={"name"}
                      label="Name"
                      className={classes.fool}
                      margin="dense"
                      variant="outlined"
                      value={name}
                      onChange={handleChangeName}
                    />
                  </Grid>
                </Grid>
                <Grid 
                  container 
                  className={`${classes.bottom} ${classes.padding}`}
                >
                <Grid item md={12} xs={12}>
                  <h4 className={classes.header}>
                    Email Adress
                  </h4>
                  <TextField
                    error={hasError('email') 
                    ? hasError('email') : 
                    props.errors 
                    && props.errors.errors
                    && props.errors.errors.email 
                      ? true
                      : false
                    }
                    name="email"
                    label="Email Adress"
                    className={classes.fool}
                    margin="dense"
                    variant="outlined"
                    value={formState.values.email}
                    onChange={handleChange}
                    helperText={ 
                      hasError('email') 
                        ? hasError('email') : 
                        props.errors 
                        && props.errors.errors
                        && props.errors.errors.email 
                          ? props.errors.errors.email 
                          : ""
                    }
                  />
                </Grid>
              </Grid>
              <ChooseResorts 
                onChangeCountry={onChangeCountry}
                onChangeState={onChangeState}
                resortsData={resortsData}
                statesData={statesData}
                countriesData={countriesData}
                onChangeResort={onChangeResort}
              />
              <Grid item>
                <Button
                  disabled={resortsData.length <= 0 
                    || name === ""
                    || formState && formState.emai === ""
                  }
                  className={classes.signInButton}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  Edit
                </Button>
              </Grid>
            </React.Fragment>
            )
            : <div className="loader" />
        }
      </div>
    </form>
    </ModalsWrapper>
  );
};

const mapStateToProps = state => {
  return {
    user: state.users.user,
    countries: state.arrays.countries,
    loading: state.arrays.loading,
    states: state.arrays.states,
    resorts: state.arrays.resorts,
    errors: state.users.errors
  };
};

const mapDispatchToProps = {
  isEditModeratorClose,
  getMultipleStatesAction,
  cleareStatesAction,
  getMultipleResortsAction,
  cleareResortsAction,
  handleDeleteErrors,
  getUsersListAction,
  editUserAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EditModerator);
