import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  actions: {
    display: "flex",
  },
  delete: {
    backgroundColor: "#e57373",
    "&:hover": {
      backgroundColor: "#f44336",
    }
  },
  button: {
    fontSize: "11px",
    color: "#ffff",
    margin: "5px"
  }
}));
