import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import { ActionButtons } from '../ActionButtons';

const useStyles = makeStyles(theme => ({
  root: {
    background: "#ffff"
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  nodata: {
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#ffff"
    }
  },
  centerText: {
    textAlign: "center",
  },
}));

const ManageMapTable = ({ data, actionsHandler, styles }) => {

  const classes = useStyles();
  return (
    <>
      {data && data.length > 0 ? data.map(map => (
        <TableRow
          className={classes.tableRow}
          hover
          key={map.id}
        >
          <TableCell>{map.country.name}</TableCell>
          <TableCell>{map.state.name}</TableCell>
          <TableCell> {map.name}</TableCell>
          <TableCell>{
            map.users.length > 0 ?
              (<span>{map.users[0].name} {map.users.length > 1 ? `and ${map.users.length - 1} others` : null}</span>)
              : <span className={classes.centerText}> 0 </span>
          }
          </TableCell>
          <TableCell className={classes.centerText}>{map.na_marks}</TableCell>
          <TableCell>
            <ActionButtons actionsHandler={actionsHandler} classes={styles} map={map} />
          </TableCell>
        </TableRow>
      )) : (<TableRow
        className={classes.tableRow}
        hover
      >
        <TableCell
          colSpan={6}
          className={classes.nodata}
        >
          <h4>No data available yet</h4>
        </TableCell>
      </TableRow>)
      }
    </>

  );
};


export default ManageMapTable;
