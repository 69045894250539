import ActionType from "../constants/modals";

const initialState = {
  isAddModerator: false,
  isEditModerator: false,
  isSubmitReset: false,
  isAddMap: false
};

const modalReducer = (state = initialState, action) => {
  switch(action.type) {
    case ActionType.OPEN_MODAL:
      return {
        ...state,
        [action.params.modalType]: true,
      };
    case ActionType.CLOSE_MODAL:
      return {
        ...state,
        [action.params.modalType]: false,
      };
    default:
      return state;
  }
};

export default modalReducer;
