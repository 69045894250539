import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  Dashboard as DashboardView,
  UserList as UserListView,
  Icons as IconsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  ForgotPassword as ForgotPasswordView,
  ManageMaps as ManageMapViews,
  ViewMap as ViewMapViews
} from './views';

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={ManageMapViews}
        exact
        layout={MainLayout}
        path="/manage-maps"
      />
      <RouteWithLayout
        component={ViewMapViews}
        exact
        layout={MainLayout}
        path="/view-map/:id/"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/manage-moderators"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <Route
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/reset-password"
      />
      <Route
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <Route
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Route
        component={ForgotPasswordView}
        exact
        layout={MinimalLayout}
        path="/forgot-password"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
