import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import { ActionButtons } from './ActionButtons';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    background: "#ffff"
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  nodata: {
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#ffff"
    }
  },
  centerText: {
    textAlign: "center",
  },
  approved: {
    color: "#3F51B5"
  },
  disapproved: {
    color: "#E57373"
  }
}));

const Table = ({ data, actionsHandler, styles, loading, resortId, map }) => {
  const classes = useStyles();
  const [marksGroups, setMarksGroups] = useState([])

  useEffect(() => {
    if (data) {
      const groups = new Map()

      data.forEach(mark => {
        const sid = mark.submission_id || null
        if (!groups.has(sid)) {
          groups.set(sid, [])
        }
        groups.get(sid).push(mark)
      })

      setMarksGroups(
        Array.from(groups).map(group => ({
          submission_id: group[0],
          items: group[1]
        }))
      )
    }
  }, [data])

  return (
    <>
      {marksGroups.length > 0 ? marksGroups.map(group => (
        <>
          {group.items.map((mark, idx) => (
            <TableRow
              className={classes.tableRow}
              hover
              key={mark.id}
            >
              {!idx && (
                <TableCell
                  style={{borderRight: '1px solid #eee', textAlign: 'center'}}
                  rowSpan={group.items.length}
                >{group.submission_id}</TableCell>
              )}
              <TableCell>{mark.user_name}</TableCell>
              <TableCell>{moment(mark.created).format('DD-MM-YYYY')}</TableCell>
              <TableCell
                className={mark.status === 0 ? '' : mark.status === 1 ? classes.approved : classes.disapproved}
              > {mark.status === 0 ? "Waiting for response" : mark.status === 1 ? "Approved" : "Disapproved"}</TableCell>
              <TableCell>
                <ActionButtons map={map} actionsHandler={actionsHandler} classes={styles} mark={mark} loading={loading} resortId={resortId} />
              </TableCell>
            </TableRow>
          ))}
        </>
      )) : (<TableRow
        className={classes.tableRow}
        hover
      >
        <TableCell
          colSpan={6}
          className={classes.nodata}
        >
          <h4>No data available yet</h4>
        </TableCell>
      </TableRow>)
      }
    </>

  );
};


export default Table;
