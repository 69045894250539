import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField, Link, Typography } from '@material-ui/core';
import SignWrapper from 'components/SignWrapper/SignWrapper';
import {connect} from "react-redux";
import {authAction, authHandlingErrors} from "../../store/actions/auth";

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },

  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#00034b',
    color: '#fff',
    '&:hover': {
      backgroundColor: ' #3c42d0'
    }
  },
  loginError: {
    background: "#fcc0ff9e",
    textAlign: "center",
    padding: "10px",
    borderRadius: "4px",
    color: "red",
    marginTop: "10px"
  }
}));

const SignIn = props => {
  const classes = useStyles();

  useEffect(()=> {
    props.authHandlingErrors();
  // eslint-disable-next-line react/jsx-sort-props
  }, [])

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    if (props.errors && Object.keys(props.errors).length !== 0) {
      props.authHandlingErrors();
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const redirectHandler = () => {
    props.history.push('/');
  };

  const handleSignIn = event => {
    const userObj = {};
    userObj.email = formState.values.email;
    userObj.password = formState.values.password;

    event.preventDefault();
    props.authAction(userObj, redirectHandler);
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <SignWrapper>
      <form className={classes.form} onSubmit={handleSignIn}>
        <Typography className={classes.title} variant="h2">
          Sign In
        </Typography>
        <TextField
          error={props.errors && props.errors.email ? true : false}
          className={classes.textField}
          error={hasError('email')}
          fullWidth
          label="Email address"
          name="email"
          onChange={handleChange}
          type="text"
          value={formState.values && formState.values.email ?  formState.values.email : ''}
          variant="outlined"
          helperText={ hasError('email') ? formState.errors.email[0] : props.errors && props.errors.email ? props.errors.email : ""}
        />
        <TextField
          autoComplete="off"
          error={props.errors && props.errors && props.errors.password ? true : false}
          className={classes.textField}
          error={hasError('password')}
          fullWidth
          label="Password"
          name="password"
          onChange={handleChange}
          type="password"
          value={formState.values && formState.values.password ? formState.values.password : ''}
          variant="outlined"
          helperText={ hasError('password') ? formState.errors.password[0] : props.errors && props.errors.email ? props.errors.email : ""}
        />
        {props.errors && props.errors.error && <div className={classes.loginError}>{props.errors.error}</div>}
        <Button
          className={classes.signInButton}
          disabled={(!formState.isValid) || (props.errors && Object.keys(props.errors).length !== 0)}
          fullWidth
          size="large"
          type="submit"
          variant="contained">
          Sign in now
        </Button>
        <Typography color="textSecondary" variant="body1">
          Forgot password?{' '}
          <Link component={RouterLink} to="/forgot-password" variant="h6">
            Forgot password
          </Link>
        </Typography>
      </form>
  </SignWrapper>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    errors: state.auth.errors
  };
};

const mapDispatchToProps = {
  authAction,
  authHandlingErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
