import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { getInitials } from 'helpers';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    background: "#ffff"
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  nodata: {
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#ffff"
    }
  },
  actions: {
    display: "flex",
  },
  delete: {
    backgroundColor: "#e57373",
    "&:hover": {
      backgroundColor: "#f44336",
    }
  },
  button: {
    fontSize: "11px",
    color: "#ffff",
    margin: "5px"
  } 
}));

const UsersTable = props => {
  const classes = useStyles();

  return (
     <React.Fragment>
      {props.users && props.users.length > 0 ? props.users.map(user => (
        <TableRow
          className={classes.tableRow}
          hover
          key={user.id}
        >
          <TableCell>
            <div className={classes.nameContainer}>
              <Avatar
                className={classes.avatar}
                src={user.logo}
              >
                {getInitials(user.name)}
              </Avatar>
              <Typography variant="body1">{user.name}</Typography>
            </div>
          </TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>
            <ul>
              {user.ski_areas && user.ski_areas.map(item=> {
                 return (
                  <li key={item.id}>
                    {item.name}
                  </li>
                 )
               })}
            </ul>
          </TableCell>
          <TableCell>
            {window.location.pathname === "/manage-moderators"
              && 
            <div className={classes.actions}>
              <Button
                 onClick={()=> props.actionsHandler("edit", user)}
                 className={`${classes.button}`}
                 color="primary"
                 variant="contained"
                >
                  Edit
               </Button>
               <Button
                 onClick={()=> props.actionsHandler("delete", user)}
                 className={`${classes.delete} ${classes.button}`}
                 color="inherit"
                 variant="contained"
                >
                  Delete
              </Button>
            </div>
            }
            

          </TableCell>
        </TableRow>
      ))
      : (<TableRow
        className={classes.tableRow}
       hover
       >
      <TableCell
        colSpan={5}
        className={classes.nodata}
      >
        <h4>No data avalible yet</h4>
      </TableCell>
    </TableRow>)
    }
    </React.Fragment>

  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
