import React from "react";
import Pagination from "react-js-pagination";
import styles from "./Pagination.module.scss";

const PaginationComponent = props => {

  const handleChange = (page) => {
    props.onChange(page);
    props.handleScrollTop();
  }
  return (
    <div className={styles.PaginationWrapper}>
      <Pagination
        hideDisabled
        activePage={props.activePage}
        itemsCountPerPage={props.itemsCountPerPage}
        totalItemsCount={props.totalItemsCount}
        pageRangeDisplayed={5}
        onChange={handleChange}
        innerClass={styles.innerClass}
        activeClass={styles.activeClass}
        activeLinkClass={styles.activeLinkClass}
        itemClass={styles.itemClass}
        itemClassFirst={styles.itemClassFirst}
        itemClassPrev={styles.itemClassPrev}
        itemClassNext={styles.itemClassNext}
        itemClassLast={styles.itemClassLast}
        disabledClass={styles.disabledClass}
        linkClass={styles.linkClass}
        linkClassFirst={styles.linkClassFirst}
        linkClassPrev={styles.linkClassPrev}
        linkClassNext={styles.linkClassNext}
        linkClassLast={styles.linkClassLast}
      />
    </div>
  );
};

export default PaginationComponent;
