import React, {useState, useEffect} from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import {connect} from "react-redux";
import {
        getMultipleStatesAction,
        cleareStatesAction,
        getMultipleResortsAction,
        cleareResortsAction,
        } from "../../../../../store/actions/arrays";
import validate from 'validate.js';
import {inviteNewUserAction, handleDeleteErrors} from "../../../../../store/actions/users";
import ChooseResorts  from "../../../../ChooseResorts";
import {getUsersListAction} from "../../../../../store/actions/users";
import {isAddModeratorClose} from "../../../../../store/actions/modals";
const useStyles = makeStyles((theme) => ({
  fool: {
    width: "100%"
  },
  header: {
    marginBottom: "10px"
  },
  margin: {
    margin: 0,
  },
  padding: {
    padding: "8px"
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#00034b',
    color: '#fff',
    '&:hover': {
      backgroundColor: ' #3c42d0'
    }
  }
}));


const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
};


const Invite = (props) => {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [resorts, setResorts] = useState([]);
  const [personName, setPersonName] = useState('');
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);


  const onChangeCountry = (value) => {
    if(value) {
      let countriesIds = [];
      value.map(countryItem => countriesIds.push(countryItem.id));
      setCountries(value);
      changeStatesArrayAction(value, countriesIds);
    }
  };

const changeStatesArrayAction = (value, countriesIds) => {
  if(value.length > 0) {
    props.getMultipleStatesAction(countriesIds, statesValueaChecker);
  } else {
    if(states.length > 0) {
      setStates([]);
      props.cleareStatesAction();
    }
    if(resorts.length > 0) {
      setResorts([]);
      props.cleareResortsAction();
    }
  }
};

const statesValueaChecker = (data) => {
  const newStatesArray = [];
  for(let i = 0; i < data.length; i++) {
    for(let j = 0; j < states.length; j++) {
       if(states[j].id === data[i].id) {
         newStatesArray.push(states[j]);
       }
    }
   }
   setStates(newStatesArray);
};
  
  const onChangeResort = (value) => {
    if(value) {
      setResorts(value);
    }
  };

  const changeResortsArrayAction = (value, resortsIds) => {
    if(value.length > 0) {
      props.getMultipleResortsAction(resortsIds, resortsValueaChecker);
    } else {
      if(resorts.length > 0) {
        setResorts([]);
        props.cleareResortsAction();
      }
    }
  };

  const resortsValueaChecker = (data) => {
    const newResortsArray = [];
  
    for(let i = 0; i < data.length; i++) {
      for(let j = 0; j < resorts.length; j++) {
         if(resorts[j].id === data[i].id) {
          newResortsArray.push(resorts[j]);
         }
      }
     }
     setResorts(newResortsArray);
  };

  const onChangeState = (value) => {
    if(value) {
      let statesIds = [];
      value.map(stateItem => statesIds.push(stateItem.id));
      setStates(value);
      changeResortsArrayAction(value, statesIds);
    }
  };

  const changePersonNameHandler = (event) => {
    let copy = JSON.parse(JSON.stringify(props.errors));
    if(copy && copy.errors && copy.errors.name) {
      delete copy.errors.name;
      props.handleDeleteErrors(copy);
    }
    setPersonName(event.target.value);
  };


  const handleChange = event => {
    event.persist();
    let copy = JSON.parse(JSON.stringify(props.errors));
    if(copy && copy.errors && copy.errors.email) {
      delete copy.errors.email;
      props.handleDeleteErrors(copy);
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    const resortsData = [];
    resorts.map(item => resortsData.push(item.id));
    const countriesData = [];
    countries.map(item => countriesData.push(item.id));
    const statesData = [];
    states.map(item => statesData.push(item.id));

    const data = {
      name: personName,
      email: formState.values.email,
      resorts: resortsData,
      countries: countriesData,
      states: statesData
    }
    props.inviteNewUserAction(data, tableDatatHandler, props.handleAlert);
  };

  const tableDatatHandler = () => {
    props.getUsersListAction();
    setCountries([]);
    setStates([]);
    setResorts([]);
    setPersonName('');
    setFormState({
      isValid: false,
      values: {},
      touched: {},
      errors: {}
    });
    props.handleDeleteErrors({});
    props.isAddModeratorClose();
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  
  return (
    <React.Fragment>
      <form className={classes.form} onSubmit={handleSubmit}>
      <Grid 
        container 
        className={`${classes.bottom} ${classes.padding}`}
       >
        <h4 className={classes.header}>
            Name
        </h4>
        <Grid item md={12} xs={12}>
          <TextField
            error={props.errors && props.errors.errors && props.errors.errors.name ? true : false }
            label="Name"
            className={classes.fool}
            margin="dense"
            variant="outlined"
            value={personName}
            onChange={changePersonNameHandler}
            helperText={ props.errors && props.errors.errors && props.errors.errors.name ? props.errors.errors.name[0] : ""}
          />
        </Grid>
      </Grid>
      <Grid 
        container 
        className={`${classes.bottom} ${classes.padding}`}
      >
        <Grid item md={12} xs={12}>
          <h4 className={classes.header}>
            Email Address
          </h4>

          <TextField
            className={classes.textField}
            error={props.errors.errors && props.errors.errors.email ? true : false }
            className={classes.fool}
            label="Email address"
            name="email"
            onChange={handleChange}
            type="text"
            value={formState.values.email || ''}
            variant="outlined"
            margin="dense"
            helperText={ props.errors.errors && props.errors.errors.email && props.errors.errors.email[0] }
        />
        </Grid>
      </Grid>
      <ChooseResorts 
        onChangeCountry={onChangeCountry}
        onChangeState={onChangeState}
        resortsData={resorts}
        statesData={states}
        countriesData={countries}
        onChangeResort={onChangeResort}
      />
      <Grid container justify="center" alignItems="center">
        <Grid item>
        <Button
          disabled={!formState.isValid 
                    || resorts.length <= 0
                    || personName === ""
                    || !formState.values.email 
                    || formState.values.email === ""
                  }
          className={classes.signInButton}
          fullWidth
          size="large"
          type="submit"
          variant="contained">
          Invite
        </Button>
        </Grid>
      </Grid>
    </form>
    </React.Fragment>

  );
};

const mapStateToProps = state => {
  return {
    countries: state.arrays.countries,
    loading: state.arrays.loading,
    states: state.arrays.states,
    resorts: state.arrays.resorts,
    errors: state.users.errors
  };
};

const mapDispatchToProps = {
  getMultipleStatesAction,
  cleareStatesAction,
  getMultipleResortsAction,
  cleareResortsAction,
  inviteNewUserAction,
  handleDeleteErrors,
  getUsersListAction,
  isAddModeratorClose
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
