import ActionType from "../constants/modals";

export const isAddModeratorOpen = () => dispatch => {
  return dispatch({
    type: ActionType.OPEN_MODAL,
    params: { modalType: 'isAddModerator' },
  });
};

export const isAddModeratorClose = () => dispatch => {
  return dispatch({
    type: ActionType.CLOSE_MODAL,
    params: { modalType: 'isAddModerator' },
  });
};

export const isDeleteModeratorOpen = () => dispatch => {
  return dispatch({
    type: ActionType.OPEN_MODAL,
    params: { modalType: 'isDeleteModerator' },
  });
};

export const isDeleteModeratorClose = () => dispatch => {
  return dispatch({
    type: ActionType.CLOSE_MODAL,
    params: { modalType: 'isDeleteModerator' },
  });
};

export const isEditModeratorOpen = () => dispatch => {
  return dispatch({
    type:  ActionType.OPEN_MODAL,
    params: { modalType: 'isEditModerator' },
  });
};

export const isEditModeratorClose = (callback) => dispatch => {
  return dispatch({
    type: ActionType.CLOSE_MODAL,
    params: { modalType: 'isEditModerator' },
  })
};

export const isSubmitResetOpen = () => dispatch => {
  return dispatch({
    type:  ActionType.OPEN_MODAL,
    params: { modalType: 'isSubmitReset' },
  });
};

export const isSubmitResetClose = () => dispatch => {
  return dispatch({
    type: ActionType.CLOSE_MODAL,
    params: { modalType: 'isSubmitReset' },
  });
};

export const isAddMapOpen = () => dispatch => {
  return dispatch({
    type:  ActionType.OPEN_MODAL,
    params: { modalType: 'isAddMap' },
  });
};

export const isAddMapClose = () => dispatch => {
  return dispatch({
    type: ActionType.CLOSE_MODAL,
    params: { modalType: 'isAddMap' },
  });
};

