import React from 'react'
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom'

export const ActionButtons = ({ actionsHandler, classes, map }) => {
  return (
    <div className={classes.actions}>
      <Button
        component={Link}
        to={`/view-map/${map.id}`}
        className={`${classes.button}`}
        color="primary"
        variant="contained"
      >
        View
  </Button>
      <Button
        onClick={(e) => actionsHandler("delete", map.id)}
        className={`${classes.delete} ${classes.button}`}
        color="inherit"
        variant="contained"
      >
        Delete
  </Button>
    </div>
  )
}
