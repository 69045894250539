import React, {useState, useEffect} from "react";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux'


const  EnhancedTableHead = (props) => {
  const {  onRequestSort } = props;
  
  const loading = useSelector(state => state.users.loading)
  const [order, setOrder] = useState(null)
  const [orderBy, setOrderBy] = useState(true)
  
  const handleOrder = name => () => {
    if (!loading){
      setOrder(name)
      setOrderBy(!orderBy)
    }
  }

  useEffect(()=>{
    let _orderBy = orderBy ? 'asc' : 'desc'
      onRequestSort(order, _orderBy);
  }, [order, orderBy])

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell) =>  (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
            >
            <TableSortLabel
              hideSortIcon={order !== headCell.id ? true : false}
              active={order === headCell.id}
              direction={orderBy? 'asc' : 'desc'}
              onClick={headCell.id !== 'assigned' ? handleOrder(headCell.id) : null}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
         <TableCell padding={'default'}
         >
            Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}


export default EnhancedTableHead;
