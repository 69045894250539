import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import EnhancedTableHead from "./EnhancedTableHead";
import {PaginationComponent} from "../../components/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  body: {
    backgroundColor: "#ffff"
  }
}));

export default function TableData(props) {

  const classes = useStyles();
  const [dense, setDense] = useState(false);

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleScrollTop = () => {
    props.topRef.current.scrollIntoView({behavior: "smooth"});
  };

  return (
    <div className={classes.root}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={props.headCells}
              classes={classes}
              order={props.order}
              orderBy={props.orderBy}
              onRequestSort={props.handleRequestSort}
              rowCount={props.rowCount}
            />
           <TableBody className={classes.body}>
              {props.children}
            </TableBody> 
          </Table>
        </TableContainer>
        {props.last_page > 1 &&
          <PaginationComponent 
            handleScrollTop={handleScrollTop}
            activePage={props.page}
            itemsCountPerPage={props.itemsCountPerPage}
            totalItemsCount={props.totalItemsCount}
            pageRangeDisplayed={5}
            onChange={props.onChange}
          />
        }
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Compact view"
      />
    </div>
  );
};
