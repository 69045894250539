import {ActionType} from "../constants/auth";

export const initialState = {
  loading: false,
  me: {},
  errors: {},
  message: "",
  email: ""
};

const auth = (state = initialState, action) => {
  switch(action.type) {
    case ActionType.AUTH_START:
      return {
        ...state,
        loading: true,
      };
    case ActionType.AUTH_SUCCESS: 
     localStorage.setItem("access_token", action.me.access_token );
      return {
        ...state,
        loading: false,
        me: action.me,
      };
    case ActionType.AUTH_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.AUTH_HANDLE_ERRORS:
      return {
        ...state,
        errors: {}
      };
    case ActionType.FOGOT_PASSWORD_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.FOGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        email: action.email
      };
    case ActionType.FOGOT_PASSWORD_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.AUTH_LOGOUT: 
      return {
        ...state,
        errors: {},
        me: {}
      };
    case ActionType.AUTH_LOGOUT_ERRORS: 
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.RESET_PASSWORD_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    default:
      return state;
  };
};

export default auth;
