import React, {useState} from "react";
import Grid from '@material-ui/core/Grid';
import ModalsWrapper from "../../ModalsWrapper";
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import {fogotPasswordAction} from "../../../../store/actions/auth";
import TransitionAlerts from "../../../../components/Alert";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "#f4f6f8",
    height: "100vh",
    padding: "20px",
    textAlign: "center",
  },
}));

const SubmitReset = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const btnHandler = (modificator) => {
    if(modificator === "login") {
      props.history.push("/sign-in")
    }
    if(modificator === "resend") {
      props.fogotPasswordAction(
        props.userEmail, 
        alertHandler
      );
    }
  };

  const alertHandler = () => {
    let promise = new Promise((res, rej)=> {
      res(setOpen(true))
    });
    promise.then(()=>{
      setTimeout(()=> {
        setOpen(false);
      }, 2000);
    })
  };

  const alertHandleClose = () => {
    setOpen(false);
  };

  return(
    <React.Fragment>
    <ModalsWrapper open={props.open}>
      <Grid container 
            justify="center" 
            alignItems="center" 
            direction="column"
            classes={{
              container: classes.container
            }}
            spacing={3}
      >
      <TransitionAlerts 
        alertHandler={alertHandleClose} 
        open={open}
        info="Reset password link is sent successfully"
      />
        <Grid item>
          <h2>We sent reset password link on (user email address). Please check your email box</h2>
        </Grid>
        <Grid item>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Button variant="contained"
                onClick={()=>btnHandler("login")}
              >
                Login
              </Button>
            </Grid>
            <Grid item>
              <Button 
                variant="contained" 
                color="primary"
                onClick={()=>btnHandler("resend")}
              >
                Resend
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalsWrapper>
    </React.Fragment>
    );
};


const mapStateToProps = state => {
  return {
    open: state.modals.isSubmitReset,
    userEmail: state.auth.email,
  };
};

const mapDispatchToProps = {
  fogotPasswordAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubmitReset));
