import React, {useState} from "react";
import ModalsWrapper from "../../ModalsWrapper";
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Invite from "./components/Invite";
import Add from "./components/Add";
import {isAddModeratorClose} from "../../../../store/actions/modals";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px",
    maxHeight: "90%",
    overflow: "visible",
    width: "100%",
    maxWidth: "540px",
    outline: "none"
  },
  acvtiveBtn: {
    backgroundColor: "#1a237e"
  },
  bottom: {
    marginBottom: "20px"
  },
  cross: {
    color: "#6f6f6f",
    textAlign: "right",
    fontWeight: "bold",
    fontSize: "24px",
    cursor: "pointer",
    position: "absolute",
    top: "2px",
    right: "14px"
  }
}));

const AddModerator = (props) => {
  const classes = useStyles();

  const [active, setActive] = useState("invite");
  
  const handleChange = (value)=> {
    setActive(value);
  };

  const handleClose = () => {
    props.isAddModeratorClose();
  };

  return(
    <ModalsWrapper open={props.open}>
      <div className={classes.paper}>
       <div className={classes.cross} onClick={handleClose}>x</div>
        <h2 
          id="simple-modal-title" 
          className={classes.bottom}
        >Add Moderator
        </h2>
        <ButtonGroup 
          variant="contained" 
          color="primary" 
          aria-label="contained primary button group"
          className={classes.bottom}
        >
          <Button  
            className={active === "invite" ? classes.acvtiveBtn : ""}
            onClick={()=>handleChange("invite")}
          >Invite New</Button>
          <Button 
            className={active === "add" ? classes.acvtiveBtn : ""}
            onClick={()=>handleChange("add")}
          >Add from web</Button>
       </ButtonGroup>
        {active === "invite" && <Invite handleAlert = {props.handleAlert}/>}
        {active === "add" && <Add handleAlert = {props.handleAlert}/>}
      </div>
    </ModalsWrapper>
  );
};

const mapStateToProps = state => {
  return {
    open: state.modals.isAddModerator,
  }
}

const mapDispatchToProps = {
  isAddModeratorClose
}

export default connect(mapStateToProps, mapDispatchToProps)(AddModerator);
