import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const ViewsToolbar = props => {
  const classes = useStyles();
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={classes.root}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />

        <Button
          color="primary"
          variant="contained"
          onClick={props.onClick}
        >
          {props.title}
        </Button>
      </div>
      {props.hideSearch ?
        null
        : <div className={classes.row}>
          <SearchInput
            handleSearch={props.handleSearch}
            className={classes.searchInput}
            placeholder={props.placeholder}
          />
        </div>
      }
    </div>
  );
};

ViewsToolbar.propTypes = {
  className: PropTypes.string
};

export default ViewsToolbar;
