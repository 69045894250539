import axiosInstance from "../axiosInstance";

export const authApi = data => {
  return axiosInstance.post(`/api/auth/login`, data);
};

export const logoutApi = () => {
  return axiosInstance.post(`/api/auth/logout`);
};

export const meApi = () => {
  return axiosInstance.post(`/api/auth/me`);
};

export const fogotPasswordApi = data => {
  return axiosInstance.post(`/api/auth/password/create`, data);
};

export const resetPasswordApi = data => {
  return axiosInstance.post(`/api/auth/password/reset`, data);
};
