import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from "./store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from './serviceWorker';
import App from './App';

const pStore = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
     <PersistGate persistor={pStore}>
      <App />
     </PersistGate>
  </Provider>
, document.getElementById('root'));

serviceWorker.unregister();
