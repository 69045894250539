export const ActionType = {
  AUTH_START: "AUTH_START",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_ERRORS: "AUTH_ERRORS",
  AUTH_HANDLE_ERRORS: "AUTH_HANDLE_ERRORS",
  FOGOT_PASSWORD_START: "FOGOT_PASSWORD_START",
  FOGOT_PASSWORD_SUCCESS: "FOGOT_PASSWORD_SUCCESS",
  FOGOT_PASSWORD_ERRORS: "FOGOT_PASSWORD_ERRORS",
  AUTH_LOGOUT: "AUTH_LOGOUT",
  AUTH_LOGOUT_ERRORS: "AUTH_LOGOUT_ERRORS",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERRORS: "RESET_PASSWORD_ERRORS",
  EIT_USER: "EDIT_USER"
};
