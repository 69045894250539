import axiosInstance from "../axiosInstance";

export const getCountries = () => {
  return axiosInstance.get(`/api/regions`);
};

export const getMultipleStates = (countries) => {
  return axiosInstance.get(`/api/regions-multiple/${countries}`);
};

export const getMultipleResorts = (states) => {
  return axiosInstance.get(`/api/resorts-multiple/${states}`);
};
