export const ActionType = {
  GET_COUNTRIES_ARRAY_START: "GET_COUNTRIES_ARRAY_START",
  GET_COUNTRIES_ARRAY_SUCCESS: "GET_COUNTRIES_ARRAY_SUCCESS",
  GET_COUNTRIES_ARRAY_ERRORS: "GET_COUNTRIES_ARRAY_ERRORS",
  GET_MULTIPLE_RESORTS_START: "GET_MULTIPLE_RESORTS_START",
  GET_MULTIPLE_RESORTS_SUCCESS: "GET_MULTIPLE_RESORTS_SUCCESS",
  GET_MULTIPLE_RESORTS_ERRORS: "GET_MULTIPLE_RESORTS_ERRORS",
  GET_MULTIPLE_STATES_START: "GET_MULTIPLE_STATES_START",
  GET_MULTIPLE_STATES_ERRORS: "GET_MULTIPLE_STATES_ERRORS",
  GET_MULTIPLE_STATES_SUCCESS: "GET_MULTIPLE_STATES_SUCCESS",
  CLEARE_STATES: "CLEARE_STATES",
  CLEARE_RESORTS: "CLEARE_RESORTS",
  DELETE_ERRORS: "DELETE_ERRORS"
};
