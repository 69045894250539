import React, { useState, useEffect } from "react";
import ModalsWrapper from "../../ModalsWrapper";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { isAddMapClose } from "../../../../store/actions/modals";
import { addMapAction } from "../../../../store/actions/maps";
import ChooseResorts from "../../../ChooseResorts";
import {
  getMultipleStatesAction,
  getMultipleResortsAction,
} from "../../../../store/actions/arrays";
import { SelectWithInfinityScroll } from "../../../../components/SelectWithInfinityScroll";
import { Uploader } from "../../../../helpers";
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px",
    maxHeight: "90%",
    overflow: "visible",
    width: "100%",
    maxWidth: "540px"
  },
  acvtiveBtn: {
    backgroundColor: "#1a237e"
  },
  bottom: {
    marginBottom: "20px"
  },
  cross: {
    color: "#6f6f6f",
    textAlign: "right",
    fontWeight: "bold",
    fontSize: "24px",
    cursor: "pointer",
    position: "absolute",
    top: "2px",
    right: "14px"
  },
  fool: {
    width: "100%",
    margin: 0
  },
  header: {
    marginBottom: "10px"
  },
}));

const AddNewMap = (props) => {
  const { errors, loading, states: statesArr } = props;

  const [resort, setResort] = useState("");
  const [states, setStates] = useState("");
  const [countries, setCountries] = useState("");
  const [user, setUser] = useState([]);
  const [picture, setPicture] = useState([]);
  const [formDisabled, setFormDisabled] = useState(true);
  const [formError, setFormError] = useState(errors);
  const classes = useStyles();

  useEffect(() => {
    const checkForm = () => {
      if (resort.length > 0
        && (typeof states === 'object' || !statesArr || !statesArr.length)
        && typeof countries === 'object'
        && picture.length > 0
        && loading === false) {
        setFormDisabled(false)
      } else setFormDisabled(true)
    }

    checkForm()
  }, [resort, states, countries, user, picture, errors, loading])

  useEffect(() => {
    setFormError(errors)
  }, [errors])

  useEffect(() => {
    if (props.edit) {

      setResort(props.map.name)
      setStates(props.map.state)
      setCountries(props.map.country)
      setUser(props.map.users)
      //setPicture(props.map.map.map_path)
    }
  }, [props.map, props.edit])

  const handleClose = () => {
    props.isAddMapClose();
    setCountries("");
    setResort("");
    setStates("");
    setPicture([])
    setUser([])
  };

  const onChangeCountry = (value) => {
    setCountries(value);
    setResort("");
    setStates("");
    props.getMultipleStatesAction(value.id);
  };

  const onChangeState = (value) => {
    setStates(value);
    setResort("");
    props.getMultipleResortsAction(value.id);
  };

  const onChangeResort = (e) => {
    setResort(e.target.value);
    if (formError?.name) setFormError({ ...formError, name: [] })
  };


  const onChangeUser = (value) => {
    setUser(value);
  };

  const onDrop = (data) => {
    setPicture(data);
  };

  const handleSuccessAdd = () => {
    handleClose()
    props.alertHandler('add')
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData()
    form.append('image', picture[0])
    form.append('name', resort)
    if (states && states.id) {
      form.append('state', states.id)
    }
    form.append('country', countries.id)
    user.map(user => {
      form.append('users[]', user.id)
    })
    props.addMapAction(handleSuccessAdd, form)
  };


  return (
    <ModalsWrapper open={props.open}>
      <form className={classes.paper} onSubmit={handleSubmit}>
        <div>
          <div className={classes.cross} onClick={handleClose}>x</div>
          <h2
            id="simple-modal-title"
            className={classes.bottom}
          >{props.edit ? "Edit Map" : "Add New Map"}
          </h2>
        </div>


        <ChooseResorts
          single={"single"}
          onChangeCountry={onChangeCountry}
          onChangeState={onChangeState}
          hideResort={true}
          resortData={resort}
          statesData={states}
          countriesData={countries}
          onChangeResort={onChangeResort}
          errors={errors}
        />
        <Grid
          container
          spacing={2}
          className={`${classes.fool}`}
        >
          <Grid item md={12} xs={12}>
            <h4 className={classes.header}>
              Resorts
            </h4>
            <TextField
              disabled={!(countries && (!statesArr.length || states))}
              error={formError?.name.length > 0 ? true : false}
              placeholder="Enter resort name"
              className={classes.fool}
              margin="dense"
              variant={"outlined"}
              value={resort}
              onChange={onChangeResort}
              helperText={formError?.name ? formError?.name[0] : ""}
            />
          </Grid>
        </Grid>


        <Grid
          container
          spacing={2}
          className={`${classes.fool}`}
        >
          <Grid item md={12} xs={12}>
            <h4 className={classes.header}>
              Registered user
            </h4>
            <SelectWithInfinityScroll
              onChange={onChangeUser}
              multiselect={true}
              value={user}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={`${classes.fool}`}
        >
          <Grid item md={12} xs={12}>
            <Uploader
              onDrop={onDrop}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button
            disabled={formDisabled}
            fullWidth
            color="primary"
            size="large"
            type="submit"
            variant="contained">
            {props.edit ? "Update Map" : "Add map"}
          </Button>
        </Grid>
      </form>
    </ModalsWrapper>
  );
};

const mapStateToProps = state => {
  return {
    open: state.modals.isAddMap,
    states: state.arrays.states
  };
};

const mapDispatchToProps = {
  isAddMapClose,
  getMultipleStatesAction,
  getMultipleResortsAction,
  addMapAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewMap);
