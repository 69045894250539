import { ActionType } from "../constants/auth";
import {
  authApi,
  fogotPasswordApi,
  logoutApi,
  resetPasswordApi
} from "../../api/requests/auth";

export const authAction = (data, redirectHandler) => dispatch => {
  return authApi(data)
    .then(
      dispatch({
        type: ActionType.AUTH_START
      })
    )
    .then(response => {
      if(response 
          && response.data 
          && response.data.user 
          && response.data.user.roles 
          && response.data.user.roles[0] 
          && response.data.user.roles[0].name 
          && (response.data.user.roles[0].name === "admin" 
              || response.data.user.roles[0].name === "moderator")
        ) {
          setTimeout(()=> {
            redirectHandler();
          }, 300);
          return dispatch({
            type: ActionType.AUTH_SUCCESS,
            me: response.data
          });
      }
      if((response 
          && response.data 
          && response.data.user 
          && response.data.user.roles 
          && response.data.user.roles[0] 
          && response.data.user.roles[0].name 
          && response.data.user.roles[0].name === "client") 
          || (response
            && response.data 
            && response.data.user 
            && response.data.user.roles.length <= 0 
          )
        ) {
        if(localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token");
        }
        dispatch({
          type: ActionType.AUTH_ERRORS,
          payload: {error: "These credentials do not match our records"}
        });
      }
    })
    .catch(errors => {
      dispatch({
        type: ActionType.AUTH_ERRORS,
        payload:
          errors.response 
          && errors.response.data 
          ? errors.response.data : {}
      });
    });
};

export const authHandlingErrors = () => dispatch => {
  return dispatch({
    type: ActionType.AUTH_HANDLE_ERRORS,
  });
};

export const logoutAction = () => dispatch => {
  return logoutApi().then(
    dispatch({
      type: ActionType.AUTH_LOGOUT
    })
  )
  .catch(error => {
    dispatch({
      type: ActionType.AUTH_LOGOUT_ERRORS
    })
  })
};

export const fogotPasswordAction = (data, redirectHandler) => dispatch => {
  return fogotPasswordApi(data)
    .then(
      dispatch({
        type: ActionType.FOGOT_PASSWORD_START
      })
    )
    .then(response => {
      redirectHandler();
      dispatch({
        type: ActionType.FOGOT_PASSWORD_SUCCESS,
        payload: response.data,
        email: data
      });
    })
    .catch(errors => {
      dispatch({
        type: ActionType.FOGOT_PASSWORD_ERRORS,
        payload:
          errors.response && errors.response.data && errors.response.data
            ? errors.response.data
            : {}
      });
    });
};

export const resetPasswordAction = (data, redirectHandler) => dispatch => {
  return resetPasswordApi(data)
    .then(response => {
      redirectHandler();
      dispatch({
        type: ActionType.RESET_PASSWORD_SUCCESS,
        payload: response.data, 
      });
    })
    .catch(errors => {
      dispatch({
        type: ActionType.RESET_PASSWORD_ERRORS,
        payload: errors.response.data 
      });
    });
};


