import axiosInstance from '../axiosInstance';

export const getMapsList = (page, search) => {
  return axiosInstance.get(`/api/admin/maps?name=${search}&page=${page}`);
};

export const addMap = (data) => {
  return axiosInstance.post(`/api/admin/maps`, data);
};

export const editMap = (id, data) => {
  return axiosInstance.post(`/api/admin/maps/${id}`, data);
};

export const getMap = (id) => {
  return axiosInstance.get(`/api/admin/maps/${id}`);
};

export const deleteMap = (id) => {
  return axiosInstance.delete(`/api/admin/maps/${id}`);
};

export const getMarks = (id, page) => {
  return axiosInstance.get(`/api/admin/maps/marks/${id}?page=${page}`);
};

export const setMarks = (id, data) => {
  return axiosInstance.post(`/api/admin/maps/mark-status/${id}`, data);
};

export const getMarkPreview = (id) => {
  return axiosInstance.get(`/api/maps/mark-preview/${id}`);
};

export const getPendingMarks = () => {
  return axiosInstance.get('/api/admin/maps/marks/pending');
};
