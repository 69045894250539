import { ActionType } from "../constants/arrays";

const initialState = {
  loading: false,
  countries: [],
  states: [],
  resorts: [],
  erros: {}
};

const arrays = (state = initialState, action) => {
  switch(action.type) {
    case ActionType.GET_COUNTRIES_ARRAY_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_COUNTRIES_ARRAY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload
      };
    case ActionType.GET_COUNTRIES_ARRAY_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.GET_MULTIPLE_STATES_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_MULTIPLE_STATES_SUCCESS:
      return {
        ...state,
        loading: false,
        states: action.payload
      };
    case ActionType.GET_MULTIPLE_STATES_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.GET_MULTIPLE_RESORTS_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_MULTIPLE_RESORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        resorts: action.payload
      };
    case ActionType.GET_MULTIPLE_RESORTS_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.CLEARE_STATES:
      return {
        ...state,
        states: []
      };
    case ActionType.CLEARE_RESORTS:
      return {
        ...state,
        resorts: []
      };
    default: 
      return state
  };
};

export default arrays;
