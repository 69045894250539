import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import TableData from "../../components/Table";
import { UsersTable } from './components';
import  ViewsToolbar from "../../components/viewsToolbar";
import {AddModerator} from "../../components/Modals";
import DeleteModerator from "../../components/Modals/ModalsComponents/DeleteModerator/";
import {connect} from "react-redux";
import {isAddModeratorOpen, isDeleteModeratorOpen, isEditModeratorOpen} from "../../store/actions/modals";
import EditModerator from "../../components/Modals/ModalsComponents/EditModerator"
import {getUsersListAction, deleteUserAction, getUserInfoAction} from "../../store/actions/users";
import TransitionAlerts from "../../components/Alert";

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'User Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email Address'},
  { id: 'assigned', numeric: true, disablePadding: false, label:'Assigned on' },
];

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    position: "relative",
  },
  content: {
    marginTop: theme.spacing(2)
  },

}));

const UserList = (props) => {
  const topRef = useRef();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [editAlertOpen, setEditAlertOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [inviteAlertOpen, setInviteAlertOpen] = useState(false)
  const [page, setPage] = useState(1);
  const [order_dir, setOrder] = useState("desc")
  const [order_by, setOrderBy] = useState("id")
  const [search, setSearch] = useState("");
  useEffect(()=> {
    props.getUsersListAction(
      search, 
      15, 
      order_by, 
      order_dir,
      page
    );
  // eslint-disable-next-line react/jsx-sort-props
  }, []);


  const handleChangePage = (newPage) => {
    setPage(newPage);
    props.getUsersListAction(
      search, 
      15, 
      order_by, 
      order_dir,
      newPage
    );
  };
  
  const getUserListAction = () => {
    props.getUsersListAction(
      search, 
      15, 
      order_by, 
      order_dir,
      page
    );
  };

  const handleRequestSort = (order_by, order_dir) => {
    props.getUsersListAction(
      search, 
      15, 
      order_by, 
      order_dir,
      page
    );
  };


  const actionsHandler = (modificator, user) => {
    if(modificator === "edit") {
      props.getUserInfoAction(user.id, openEditModal);
    }
    if(modificator === "delete") {
      props.getUserInfoAction(user.id, openDeleteModal);
    }
  };

  const openEditModal = () => {
      props.isEditModeratorOpen();
  };

  const openDeleteModal = () => {
    props.isDeleteModeratorOpen();
};

  const deleteHandler = () => {
    getUserListAction();
  };

  const addNewHandler = () => {
    props.isAddModeratorOpen();
  };

  const handleAlert = (type) => {
    if (type === 'delete') setDeleteAlertOpen(true)
    if (type === 'edit') setEditAlertOpen(true)
    if (type === 'invite') setInviteAlertOpen(true)
  };

  const alertHandler = (type) => {
    let promise = new Promise((res, rej)=> {
      res(handleAlert(type))
    });
    promise.then(()=>{
      setTimeout(()=> {
        alertHandleClose();
      }, 2000);
    })
  };

  const alertHandleClose = () => {
    setDeleteAlertOpen(false)
    setEditAlertOpen(false)
    setInviteAlertOpen(false)
  }


  const handleSearch = (value) => {
    setSearch(value);
    props.getUsersListAction(
      value, 
      15, 
      order_by, 
      order_dir,
      page
    );
  };

  return (
    <div className={classes.root} ref={topRef}>
      { props.users 
        && props.users.data 
        && props.users.meta
        && props.users.meta.current_page
        && props.users.meta.last_page
        && props.users.meta.per_page
        ?(
        <React.Fragment>
         <TransitionAlerts 
           alertHandler={alertHandleClose} 
           open={deleteAlertOpen}
           info="User deleted successfully"
         />
        <TransitionAlerts 
           alertHandler={alertHandleClose} 
           open={editAlertOpen}
           info="User information successfully edited"
         />
        <TransitionAlerts 
           alertHandler={alertHandleClose} 
           open={inviteAlertOpen}
           info="User invited successfully"
         />
         <EditModerator 
           search={search}
           page={page}
           order_dir={order_dir}
           order_by={order_by}
           open={props.editOpen}
           alertHandler = {alertHandler}
         />
          <AddModerator handleAlert = {()=>alertHandler('invite')}/>
          <DeleteModerator deleteHandler = {deleteHandler} alertHandler = {alertHandler}/>
              <ViewsToolbar 
                placeholder = {"Search moderator"}
                onClick={addNewHandler}
                title={"Add new"}
                handleSearch={handleSearch}
              />
          <div className={classes.content}>
              <TableData
                headCells = {headCells}
                page={props.users.meta.current_page}
                itemsCountPerPage={props.users.meta.per_page}
                totalItemsCount={props.users.meta.total}
                topRef={topRef}
                orderBy={order_by}
                order={order_dir}
                users={props.users.data} 
                onChange={handleChangePage}
                handleRequestSort={handleRequestSort}
                last_page={props.users.meta.last_page}
                >
                 <UsersTable 
                  actionsHandler={actionsHandler}
                  users={props.users.data} 
                  />
               </TableData>
          </div>
        </React.Fragment>
        )
        : (<div className="loader"></div>)
      }
    </div>
  );
};

const mapDispatchToProps = {
  isAddModeratorOpen,
  isDeleteModeratorOpen,
  isEditModeratorOpen,
  getUsersListAction,
  deleteUserAction,
  getUserInfoAction
};

const mapStateToProps = state => {
  return {
    editOpen: state.modals.isEditModerator,
    users: state.users.users
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
